import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AddToCartModal from "./AddToCartModal";
import { calculateDiscount } from "../../utils/Calculations";
import { useNavigate } from "react-router-dom";

const ItemCard = ({ item, category }) => {
  const navigate = useNavigate();
  const { name, price, discount, imageUrl, inStock } = item;
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleShowItem = () => {
    const categoryName = category.toLowerCase().trim().replaceAll(" ", "-");
    const itemName = item?.name.toLowerCase().trim().replaceAll(" ", "-");
    navigate(`/${categoryName}/${itemName}/${item.itemCode}`);
  };

  return (
    <>
      <AddToCartModal item={item} isOpen={isOpen} handleClose={handleClose} />
      <Card
        sx={{ width: "100%", position: "relative", cursor: "pointer", boxShadow: 5 }}
        onClick={handleShowItem}
      >
        {!inStock ? (
          <Box
            className="discount-badge"
            sx={{ fontSize: { xs: "12px" } }}
          >{`Sold out`}</Box>
        ) : discount ? (
          <Box
            className="discount-badge"
            sx={{ fontSize: { xs: "10px", sm: "16px" } }}
          >{`${discount}% off`}</Box>
        ) : (
          <></>
        )}
        <CardMedia
          style={{
            width: "100%",
            aspectRatio: 1 / 0.75,
          }}
          image={imageUrl}
          title={name}
          className="item-image"
          component="img"
          loading="lazy"
        />
        <CardContent
          sx={{ height: { xs: 75, sm: 75, md: 80 }, p: 0, pl: "10px" }}
        >
          <Box sx={{ height: { xs: 30, sm: 30, md: 35 }, overflow: "hidden" }}>
            <Typography variant="h4" sx={{ pt: "8px" }} color="primary">
              {name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {discount ? (
              <Typography color="rgba(0, 0, 0, 0.54)">
                <del>Rs{item.price}</del> &nbsp; Rs
                {calculateDiscount(price, discount)}
              </Typography>
            ) : (
              <Typography color="rgba(0, 0, 0, 0.54)">Rs{price}</Typography>
            )}
            <IconButton
              disabled={!inStock}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(true);
              }}
            >
              <AddShoppingCartIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ItemCard;
