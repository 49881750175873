import { UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addNewItem, editItem } from "../../Redux/Actions/ItemActon";
import { ToastAction } from "../../Redux/Actions/ToastAction";
import { useParams } from "react-router-dom";

const REQUIRED = "This field is required";
const NUMBER = "Enter number only";
const NUMBER_REG = /^[0-9]+$/; // eslint-disable-line

const AddItems = () => {
  const params = useParams();
  const { item, categoriesList } = useSelector((state) => state?.Items);
  const { handleSubmit, control, reset, setValue } = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleImageUpload = async () => {
    if (!selectedImage) return;
    const formData = new FormData();
    formData.append("image", selectedImage);
    try {
      const { data } = await axios.post(
        `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMGBB_KEY}`,
        formData
      );
      if (data) {
        setIsImageUploaded(true);
        setImageUrl(data.data.url);
        dispatch(
          ToastAction({
            message: "Image successfully uploaded",
            severity: "success",
          })
        );
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsImageUploaded(false);
      setSelectedImage(null);
      dispatch(
        ToastAction({
          message: "Failed image uploading",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (item?._id && params?.itemId === item?._id) {
      setValue("name", item?.name);
      setValue("description", item?.description);
      setValue("discount", item?.discount);
      setValue("inStock", item?.inStock);
      setValue("itemCode", item?.itemCode);
      setValue("maxOrderLimit", item?.maxOrderLimit);
      setValue("packingCharges", item?.packingCharges);
      setValue("price", item?.price);
      setValue("type", item?.type);
      setValue("category", item?.category?._id);
      setIsImageUploaded(true);
      setImageUrl(item?.imageUrl);
      setSelectedImage(true);
    } else {
      reset();
      setSelectedImage(null);
      setIsImageUploaded(false);
      setImageUrl("");
    }
  }, [item, setValue, params?.itemId, reset]);

  const handleAddNewItem = (data) => {
    const payload = {
      ...data,
      price: Number(data.price),
      discount: Number(data.discount),
      itemCode: Number(data.itemCode),
      packingCharges: Number(data.packingCharges),
      maxOrderLimit: Number(data.maxOrderLimit),
      imageUrl: imageUrl,
    };

    if (item?._id && params?.itemId === item._id) {
      dispatch(editItem({ ...payload, _id: item?._id }));
    } else {
      dispatch(addNewItem(payload));
      reset();
      setSelectedImage(null);
      setIsImageUploaded(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        my: "20px",
      }}
    >
      <Card
        sx={{
          minWidth: {
            xs: "90%",
            sm: "90%",
            md: "800px",
            borderRadius: "10px",
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              className="center"
              color="#fff"
              variant="h2"
              sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
            >
              {params?.itemId ? "Edit Item" : "Add New Item"}
            </Typography>
          }
          component="div"
          sx={{
            background: "#E3B625",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            mt: "20px",
          }}
        >
          <Box sx={{ width: "90%" }}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Name"
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Name"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
              rules={{
                required: REQUIRED,
              }}
            />
          </Box>
          <Box sx={{ width: "90%" }}>
            <Controller
              name="itemCode"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Product Code"
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Product Code (Must be unique)"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
              rules={{
                required: REQUIRED,
                type: "number",
                pattern: {
                  value: NUMBER_REG,
                  message: NUMBER,
                },
              }}
            />
          </Box>
          <Box sx={{ width: "90%" }}>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Description"
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Description"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  multiline
                  maxRows={4}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
              rules={{
                required: REQUIRED,
              }}
            />
          </Box>
          <Box sx={{ width: "90%" }}>
            <Controller
              name="price"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Price"
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Price"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
              rules={{
                required: REQUIRED,
                type: "number",
                pattern: {
                  value: NUMBER_REG,
                  message: NUMBER,
                },
              }}
            />
          </Box>
          <Box sx={{ width: "90%" }}>
            <Controller
              name="discount"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Discount"
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Discount"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
              rules={{
                required: REQUIRED,
                type: "number",
                pattern: {
                  value: NUMBER_REG,
                  message: NUMBER,
                },
              }}
            />
          </Box>
          <Box sx={{ width: "90%" }}>
            <Controller
              name="packingCharges"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Packing Charges"
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Packing Charges"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
              rules={{
                required: REQUIRED,
                type: "number",
                pattern: {
                  value: NUMBER_REG,
                  message: NUMBER,
                },
              }}
            />
          </Box>
          <Box sx={{ width: "90%" }}>
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Type"
                  variant="standard"
                  placeholder="Type i.e. Fresh, Organic"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
            />
          </Box>
          <Box sx={{ width: "90%" }}>
            <Controller
              name="maxOrderLimit"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Maximum Order Limit"
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Maximum order limit"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
              rules={{
                required: REQUIRED,
                type: "number",
                pattern: {
                  value: NUMBER_REG,
                  message: NUMBER,
                },
              }}
            />
          </Box>

          <FormControl variant="standard" sx={{ width: "90%" }}>
            <InputLabel sx={{ color: "#E3B625" }}>Category</InputLabel>
            <Controller
              name="category"
              variant="standard"
              control={control}
              render={({ field }) => (
                <Select {...field} sx={{ width: "100%" }} value={field.value}>
                  {categoriesList?.map((category) => (
                    <MenuItem key={category?._id} value={category?._id}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <Box sx={{ width: "90%" }}>
            <Controller
              name="inStock"
              control={control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={<Checkbox value={field.value} {...field} />}
                    label="Check if Product is In Stock"
                    sx={{ color: "#E3B625" }}
                  />
                );
              }}
            />
          </Box>

          <label htmlFor="upload-image" style={{ width: "90%" }}>
            <Box
              sx={{
                width: "100%",
                border: "2px dashed black",
                height: "150px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              {selectedImage || imageUrl ? (
                <img
                  src={
                    // imageUrl ? imageUrl : URL?.createObjectURL(selectedImage)
                    imageUrl
                  }
                  alt="Selected"
                  height={"130px"}
                />
              ) : (
                <>
                  <UploadFile sx={{ fontSize: "48px" }} />
                  <Box>Upload Image</Box>
                </>
              )}
            </Box>
          </label>
          <Box display="flex" alignItems="center">
            <Input
              id="upload-image"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <Button
              onClick={handleImageUpload}
              variant="contained"
              color="primary"
              sx={{
                display: selectedImage ? "block" : "none",
                borderRadius: "50px",
              }}
            >
              Upload
            </Button>
          </Box>

          <Button
            disabled={!isImageUploaded}
            sx={{ borderRadius: "50px" }}
            onClick={handleSubmit(handleAddNewItem)}
          >
            {params?.itemId ? "Update Item" : "Add Item"}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AddItems;
