/**
 *
 * All the theme colors typography and mui components can be modify from here.
 *
 */

import { createTheme } from "@mui/material";

const theme2 = createTheme();
const theme = createTheme({
  breakpoints: {
    values: {
      ...theme2.breakpoints.values,
      xl: 1330,
      m370: 370,
      m340: 340,
    },
  },
  palette: {
    common: {
      black: "#191632",
      white: "#fff",
      bg: "#D8F2FF",
    },
    primary: {
      // main: "#5F2EE5",
      // dark: "#0F1940",
      // light: "#494949",
      // contrastText: "#fff",
      main: "#E3B625",
      dark: "#0F1940",
      light: "#494949",
      contrastText: "#fff",
    },
    secondary: {
      // main: "#FFC600",
      dark: "#c79600",
      light: "#fff94f",
      contrastText: "#000000",
      main: "#229ED9",
    },
    info: {
      main: "#2959b3",
    },
    text: {
      primary: "#191632",
      secondary: "#5F2EE5",
      disabled: "#807f8f",
    },
    textColors: {
      optional: "#C6C6C6",
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
      fontSize: "18px",
      fontFamily: "'Baloo Bhaijaan 2', cursive",
    },
    h6: {
      fontWeight: 500,
      fontSize: "16px",
      fontFamily: "'Baloo Bhaijaan 2', cursive",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: "primary",
        variant: "contained",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "8px 32px",
          borderRadius: "50px",
          [theme2.breakpoints.down("md")]: {
            padding: "5px 20px",
          },
          boxShadow: "none",
        },
        contained: {
          "&:hover": {
            backgroundColor: "#e3d025",
          },
        },
        containedSecondary: {
          fontWeight: 700,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: "400",
          "&.Mui-error": {
            fontSize: "12px",
            marginLeft: 0,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            background: "#FBFBFC",
            border: "1px solid #DBDBDB",
            borderRadius: "4px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& input": {
            padding: "7px 10px",
            [theme2.breakpoints.down("md")]: {
              padding: "10px 8px",
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "16px 0",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          background: "#fff",
          border: "1px solid #DBDBDB",
        },
      },
    },
  },
});

theme.typography.body1 = {
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  fontSize: "16px",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
};
theme.typography.body2 = {
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  fontSize: "16px",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
};
theme.typography.button = {
  textTransform: "none",
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  fontWeight: 600,
  fontSize: "16px",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
};

theme.typography.h1 = {
  fontWeight: 700,
  fontSize: "40px",
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  [theme.breakpoints.down("lg")]: {
    fontSize: "36px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "32px",
  },
};
theme.typography.h2 = {
  fontWeight: 700,
  fontSize: "36px",
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  [theme.breakpoints.down("lg")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "22px",
  },
};

theme.typography.h3 = {
  fontWeight: 700,
  fontSize: "22px",
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  [theme.breakpoints.down("lg")]: {
    fontSize: "19px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
};
theme.typography.h4 = {
  fontWeight: 700,
  fontSize: "20px",
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
};
theme.typography.h5 = {
  fontWeight: 700,
  fontSize: "18px",
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
};
theme.typography.h6 = {
  fontWeight: 500,
  fontSize: "18px",
  fontFamily: "'Baloo Bhaijaan 2', cursive",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
};
export default theme;
