import { all, put, takeEvery } from "redux-saga/effects";
import { TOAST_HIDE, TOAST_INIT, TOAST_SHOW } from "../Actions/ToastAction";

export function* showToastSaga(action) {
  try {
    if (action.payload.hide) {
      yield put({ type: TOAST_HIDE });
    } else {
      yield put({ type: TOAST_SHOW, payload: action.payload });
    }
  } catch (e) {
    return yield put({ type: TOAST_HIDE });
  }
}

function* watchToast() {
  yield takeEvery(TOAST_INIT, showToastSaga);
}

export default function* ToastSaga() {
  yield all([watchToast()]);
}
