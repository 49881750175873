import { all, put, takeLatest } from "redux-saga/effects";
import {
  SIDEBAR_HIDE,
  SIDEBAR_INIT,
  SIDEBAR_SHOW,
} from "../Actions/ToggleAction";

export function* ToggleSideBarSaga(action) {
  try {
    if (action.payload) {
      yield put({ type: SIDEBAR_SHOW });
    } else {
      yield put({ type: SIDEBAR_HIDE });
    }
  } catch (error) {
    return yield put({ type: SIDEBAR_HIDE });
  }
}

function* watchSideBar() {
  yield takeLatest(SIDEBAR_INIT, ToggleSideBarSaga);
}

export default function* ToggleSaga() {
  yield all([watchSideBar()]);
}
