import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
const UserRoutes = () => {
  const { user, token } = useSelector((state) => state.Auth);
  if (
    (user?.type === "admin" ||
      user?.type === "seller" ||
      user?.type === "customer") &&
    token
  ) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default UserRoutes;
