import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllOrdersDetail,
  updateOrderAction,
} from "../../Redux/Actions/OrderAction";
import { Box, Card, Chip, MenuItem, Select, Stack } from "@mui/material";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import theme from "../../theme";
import { RETURN_TYPE, STATUS } from "../../utils/Enums";
import CustomePagination from "../../Components/SharedComponents/CustomPagination";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";

const status = [
  STATUS.PROCESSING,
  STATUS.READY_TO_PICK,
  STATUS.ISSUED_FOR_DELIVERY,
  STATUS.DELIVERED,
  STATUS.CANCELLED,
];

const returnTypes = [
  RETURN_TYPE.NOT_RETURNED,
  RETURN_TYPE.PARTIAL_RETURN,
  RETURN_TYPE.FULLY_RETURN,
];
const ProcessOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allOrders, totalCount, loading } = useSelector(
    (state) => state.Order
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState("10");

  const handleNavigate = (id) => {
    navigate(`/order/${id}`);
  };
  useEffect(() => {
    dispatch(
      fetchAllOrdersDetail({
        skip: currentPage * Number(pageSize),
        limit: pageSize,
        status: STATUS.PROCESSING,
      })
    );
  }, [dispatch, pageSize, currentPage]);

  const handleUpdateStatus = (data) => {
    dispatch(updateOrderAction(data));
  };
  const columns = [
    {
      field: "id",
      headerName: "OrderId#",
      width: 90,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date Time",
      width: 150,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {formatDate(cellValues.value)}
          </div>
        );
      },
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 120,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "returnAmount",
      headerName: "Returned Amount",
      width: 145,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "pendingAmount",
      headerName: "Pending Amount",
      width: 140,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.row.status === STATUS.DELIVERED
              ? "0"
              : cellValues.value}
          </div>
        );
      },
    },
    {
      field: "returnType",
      headerName: "Return Status",
      width: 200,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Select
            variant="standard"
            disableUnderline
            sx={{ width: "100%", textAlign: "center", fontWeight: 500 }}
            value={cellValues.value}
            onChange={(e) =>
              handleUpdateStatus({
                _id: cellValues.row._id,
                returnType: e.target.value,
              })
            }
            renderValue={(selected) => (
              <Chip
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: 500,
                }}
                label={cellValues.value}
              />
            )}
          >
            {returnTypes?.map((type, idx) => (
              <MenuItem key={idx} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Select
            variant="standard"
            disableUnderline
            sx={{ width: "100%", textAlign: "center", fontWeight: 500 }}
            value={cellValues.value}
            onChange={(e) =>
              handleUpdateStatus({
                _id: cellValues.row._id,
                status: e.target.value,
              })
            }
            renderValue={(selected) => (
              <Chip
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: 500,
                }}
                label={selected}
              />
            )}
          >
            {status?.map((status, idx) => (
              <MenuItem key={idx} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: "_id",
      headerName: "Details",
      width: 90,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Chip
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: 500,
              background: theme.palette.primary.main,
              cursor: "pointer",
            }}
            label="Details"
            onClick={() => {
              handleNavigate(cellValues.value);
            }}
          />
        );
      },
    },
  ];

  return !loading ? (
    <Box sx={{ p: "16px" }}>
      <Card
        sx={{
          height: "auto",
          width: "100%",
          borderRadius: "12px",
          maxWidth: "1050px",
          m: "auto",
        }}
      >
        <DataGrid
          disableSelectionOnClick
          pageSize={Number(pageSize)}
          columns={columns}
          rows={allOrders.map((order, idx) => ({
            id: idx + 1,
            pendingAmount: order.totalAmount,
            ...order,
          }))}
          rowCount={totalCount || 0}
          page={currentPage}
          paginationMode="server"
          disableColumnMenu={true}
          sx={{
            borderRadius: "12px",
            "&.MuiDataGrid-root": {
              border: "none",
              color: theme?.palette.text.disabled,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F5F5F5",
              fontSize: 16,
              color: theme.palette.text.primary,
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              maxWidth: "3px",
              maxHeight: "3px",
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Result Found
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Local filter returns no result
              </Stack>
            ),
            Footer: () => (
              <GridFooterContainer
                sx={{
                  backgroundColor: "#F5F5F5",
                  position: "relative",
                  height: "55px",
                }}
              >
                <CustomePagination
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalCount={totalCount || 0}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </GridFooterContainer>
            ),
          }}
        />
      </Card>
    </Box>
  ) : (
    <>Loading...</>
  );
};

export default ProcessOrders;
