import {
  CREATE_ORDER_FAIL,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CUSTOMER_ORDERS_FAIL,
  CUSTOMER_ORDERS_REQUEST,
  CUSTOMER_ORDERS_SUCCESS,
  FETCH_ALL_ORDERS_FAIL,
  FETCH_ALL_ORDERS_REQUEST,
  FETCH_ALL_ORDERS_SUCCESS,
  FETCH_ORDER_FAIL,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
} from "../Actions/OrderAction";

const initialState = {
  allOrders: [],
  orders: [],
  order: {},
  totalCount: 0,
  loading: false,
  error: "",
};

export const OrderReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case CREATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    case CREATE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CUSTOMER_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload?.orders,
        totalCount: action.payload?.totalCount,
      };
    case CUSTOMER_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    case FETCH_ORDER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ALL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOrders: action.payload?.orders,
        totalCount: action.payload?.totalCount,
      };
    case FETCH_ALL_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        allOrders: [],
        totalCount: 0,
      };
    case UPDATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload,
        allOrders: [
          ...state.allOrders.map((order) =>
            order._id === action.payload._id ? action.payload : order
          ),
        ],
      };
    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
