import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addNewCategory } from "../../Redux/Actions/ItemActon";

const REQUIRED = "This field is required";

const AddCategories = () => {
  const { handleSubmit, control, reset } = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();

  const handleAddNewItem = (data) => {
    const payload = {
      name: data.name,
    };
    dispatch(addNewCategory(payload));
    reset();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        my: "20px",
      }}
    >
      <Card
        sx={{
          minWidth: {
            xs: "90%",
            sm: "90%",
            md: "800px",
            borderRadius: "10px",
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              className="center"
              color="#fff"
              variant="h2"
              sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
            >
              Add New Category
            </Typography>
          }
          component="div"
          sx={{
            background: "#E3B625",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            mt: "20px",
          }}
        >
          <Box sx={{ width: "90%" }}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Name"
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Name"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ sx: { color: "#E3B625" } }}
                />
              )}
              rules={{
                required: REQUIRED,
              }}
            />
          </Box>

          <Button
            sx={{ borderRadius: "50px" }}
            onClick={handleSubmit(handleAddNewItem)}
          >
            Add Category
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AddCategories;
