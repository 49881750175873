import {
  AddCircleRounded,
  CancelRounded,
  RemoveCircleRounded,
} from "@mui/icons-material";
import { Box, Card, CardMedia, IconButton, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
  addToCartAction,
  removeFromCartAction,
} from "../../Redux/Actions/CartAction";
import { calculateDiscount } from "../../utils/Calculations";

const CartItem = ({ cart }) => {
  const dispatch = useDispatch();
  const { quantity, item } = cart;
  const { name, price, discount, imageUrl } = item;

  const handleIncCart = () => {
    if (quantity < 10) {
      const payload = { item: item, quantity: quantity + 1 };
      dispatch(addToCartAction(payload));
    }
  };

  const handleDecCart = () => {
    if (quantity > 1) {
      const payload = { item: item, quantity: quantity - 1 };
      dispatch(addToCartAction(payload));
    }
  };

  const handleRemoveCart = () => {
    dispatch(removeFromCartAction(item));
  };
  return (
    <>
      <Card
        sx={{
          // m: "15px 20px 0",
          position: "relative",
          p: "5px",
          maxWidth: "550px",
          width: "100%",
        }}
      >
        <IconButton className="cross-cart" onClick={handleRemoveCart}>
          <CancelRounded color="error" />
        </IconButton>
        <Box className="card-box">
          <CardMedia
            sx={{
              width: { xs: "30%", m370: "45%" },
              aspectRatio: 1 / 0.75,
            }}
            image={imageUrl}
            title={name}
          />
          <Box className="sub-wrapper">
            <Box
              className="typo-wrapper"
              sx={{ mt: { xs: "20px", sm: "0px" } }}
            >
              <Typography variant="h4" sx={{ pt: "8px" }}>
                {name}
              </Typography>

              <Typography variant="h4" sx={{ pt: "8px" }} color="primary">
                Rs{calculateDiscount(price, discount) * quantity}
              </Typography>
            </Box>

            <Box
              className="quantity-wrapper"
              sx={{
                height: "70px",
              }}
            >
              <Box className="counter-wrapper">
                <Box className="dec-wrapper">
                  <IconButton onClick={handleDecCart}>
                    <RemoveCircleRounded color="primary" />
                  </IconButton>
                </Box>
                <Box className="num-wrapper">{quantity}</Box>
                <Box className="inc-wrapper">
                  <IconButton onClick={handleIncCart}>
                    <AddCircleRounded color="primary" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CartItem;
