import React from "react";
import "./styles.css";
const Headline = () => {
  return (
    <div className="slider-container">
      <div className="slider-content">
        Dear Customers, Get fresh fruits and vegetables at Mandi rates. Order
        timings are from 01:00 am to 11:00 am. Orders placed after 11:00 am will
        be processed the next day. Prices are updated on a daily basis.
      </div>
    </div>
  );
};

export default Headline;
