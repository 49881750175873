import { PhoneIphone } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/forgot-password/verify");
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
      }}
    >
      <Card
        sx={{
          minWidth: {
            xs: "90%",
            sm: "500px",
            md: "500px",
            borderRadius: "10px",
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              className="center"
              color="#fff"
              variant="h2"
              sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
            >
              Reset Password
            </Typography>
          }
          component="div"
          sx={{
            background: "#E3B625",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "30px",
            mt: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "300px",
            }}
          >
            <PhoneIphone sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              variant="standard"
              placeholder="03xx xxxxxxx"
              sx={{ width: "100%" }}
            />
          </Box>
          <Button
            variant="outlined"
            sx={{ borderRadius: "50px" }}
            onClick={handleNext}
          >
            Next
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgotPassword;
