import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAddressAction,
  fetchCitiesAction,
} from "../../Redux/Actions/AddressAction";

const AddressInfo = ({ isModal, handleClose }) => {
  const { token, user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const [address, setAddress] = useState({
    street: "",
    sector: " ",
    cityId: " ",
    label: "",
    streetError: false,
    sectorError: false,
    cityError: false,
    labelError: false,
  });

  const { cities } = useSelector((state) => state.Address);

  const selectedCity = useMemo(
    () => cities.find((city) => city?._id === address?.cityId),
    [address.cityId, cities]
  );
  useEffect(() => {
    dispatch(fetchCitiesAction());
  }, [dispatch]);

  const handleAddressError = (name) => {
    setAddress((address) => ({ ...address, [name]: true }));
  };

  const handleAddAddress = () => {
    if (isModal) {
      if (!address.label.trim()) {
        handleAddressError("labelError");
        return;
      }
    }
    if (!address.cityId.trim()) {
      handleAddressError("cityError");
      return;
    }
    if (!address.sector.trim()) {
      handleAddressError("sectorError");
      return;
    }
    if (!address.street.trim()) {
      handleAddressError("streetError");
      return;
    }
    const payload = {
      title: isModal ? address.label : "Home",
      address: address.street,
      sector: address.sector,
      city: address.cityId,
      isSelected: true,
      user: user._id,
    };
    dispatch(addNewAddressAction(payload));
    if (isModal) handleClose();
  };

  return (
    <Card className="address-info">
      <Typography variant="h2" color="primary">
        Delivery Address
      </Typography>
      {isModal && (
        <TextField
          helperText={address.labelError && "Please enter Label"}
          error={address.labelError}
          sx={{ mt: "10px" }}
          fullWidth
          variant="standard"
          placeholder="Label: e.g, Home, office etc"
          value={address.label}
          onChange={(e) =>
            setAddress((address) => ({
              ...address,
              label: e.target.value,
              labelError: false,
            }))
          }
        />
      )}
      <Box
        sx={{
          minWidth: "200px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          mt: "20px",
        }}
      >
        <FormControl fullWidth error={address.cityError}>
          <Select
            fullWidth
            variant="standard"
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={address.cityId}
            sx={{ color: address.cityId === " " && "#999999" }}
            onChange={(e) =>
              setAddress((address) => ({
                ...address,
                cityId: e.target.value,
                cityError: false,
              }))
            }
          >
            <MenuItem value=" ">Select City</MenuItem>
            {cities?.map((city, index) => (
              <MenuItem key={index} value={city?._id}>
                {city?.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {address.cityError && "Please select a City"}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth error={address.sectorError}>
          <Select
            fullWidth
            variant="standard"
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={address.sector}
            sx={{ color: address.sector === " " && "#999999"}}
            onChange={(e) =>
              setAddress((address) => ({
                ...address,
                sector: e.target.value,
                sectorError: false,
              }))
            }
          >
            <MenuItem value=" ">Select Area</MenuItem>
            {selectedCity?.sector?.sort()?.map((area, index) => (
              <MenuItem key={index} value={area}>
                {area}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {address.sectorError && "Please select your Area"}
          </FormHelperText>
        </FormControl>
      </Box>
      <TextField
        helperText={address.streetError && "Please enter your address"}
        error={address.streetError}
        sx={{ mt: "20px" }}
        fullWidth
        variant="standard"
        placeholder="Delivery Address"
        value={address.street}
        onChange={(e) =>
          setAddress((address) => ({
            ...address,
            street: e.target.value,
            streetError: false,
          }))
        }
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mt: "16px",
          gap: "20px",
        }}
      >
        <Button variant="outlined" onClick={handleAddAddress} disabled={!token}>
          {isModal ? "Add Address" : "Add Details"}
        </Button>
        {isModal && <Button onClick={handleClose}>Close</Button>}
      </Box>
    </Card>
  );
};

export default AddressInfo;
