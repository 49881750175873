export const PAYMENT_METHOD = {
  CASH_ON_DELIVERY: "Cash On Delivery",
  JAZZCASH: "Jazzcash",
  EASY_PAISA: "Easy Paisa",
};

export const STATUS = {
  CANCELLED: "Cancelled",
  PROCESSING: "Processing",
  READY_TO_PICK: "Ready to Pick",
  ISSUED_FOR_DELIVERY: "Issued for Delivery",
  DELIVERED: "Delivered",
};

export const RETURN_TYPE = {
  NOT_RETURNED: "Not Returned",
  PARTIAL_RETURN: "Partially Returned",
  FULLY_RETURN: "Fully Ruturned",
};
