export const calculateDiscount = (price, discount) => {
  return Math.floor(price - (discount * price) / 100);
};

export const calculateTotal = (cartItems, city = "Kamoke") => {
  let subTotal = 0;
  let packingTotal = 0;
  let deliveryCharges = 100;
  let grandTotal = 0;
  let amount = 0;
  let discountedAmount = 0;
  cartItems?.forEach((data) => {
    const { item, quantity } = data;
    const { price, discount, packingCharges } = item;
    const discountedPrice = calculateDiscount(price, discount);
    subTotal += discountedPrice * quantity;
    packingTotal += packingCharges;
    amount += price * quantity;
  });
  if (city === "Kamoke" && subTotal >= 1000) {
    deliveryCharges = 0;
  } else if (city === "Kamoke" && subTotal < 1000) {
    deliveryCharges = 100;
  } else if (city === "Gujranwala" && subTotal >= 3000) {
    deliveryCharges = 0;
  } else if (city === "Gujranwala" && subTotal < 3000) {
    deliveryCharges = 250;
  }
  grandTotal = subTotal + deliveryCharges + packingTotal;
  discountedAmount = amount - subTotal;
  return {
    subTotal,
    deliveryCharges,
    packingTotal,
    grandTotal,
    amount,
    discountedAmount,
  };
};
