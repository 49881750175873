import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./common.css";

const CustomePagination = ({
  pageSize,
  setPageSize,
  totalCount,
  currentPage,
  setCurrentPage,
}) => {
  const pageCount = Math.ceil(totalCount / pageSize);
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: "20px",
          display: "flex",
          gap: "5px",
        }}
      >
        <Typography className="footer-result">Showing</Typography>
        <Select
          className="select-height"
          IconComponent={ExpandMoreIcon}
          value={pageSize}
          onChange={(e) => {
            setPageSize(e.target.value);
          }}
        >
          <MenuItem value={"5"}>5</MenuItem>
          <MenuItem value={"10"}>10</MenuItem>
          <MenuItem value={"20"}>20</MenuItem>
        </Select>
        <Typography className="footer-result">
          of <b>{totalCount}</b> results
        </Typography>
      </Box>
      <Box sx={{ position: "absolute", right: "10px" }}>
        <Grid container alignItems="center" style={{ paddingTop: 8 }}>
          <Grid item>
            <IconButton
              disabled={currentPage === 0}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <NavigateBeforeRoundedIcon
                sx={{ color: currentPage === 0 ? "disabled" : "black" }}
              />
              <Typography
                sx={{
                  fontWeight: "600",
                  color: currentPage === 0 ? "disabled" : "black",
                }}
              >
                Prev
              </Typography>
            </IconButton>
          </Grid>
          <Grid item>
            <Box display="flex">
              {currentPage !== 0 && (
                <Box sx={{ p: "3px 6px" }}>{currentPage}</Box>
              )}
              <Box className="page-number">{currentPage + 1}</Box>
              {currentPage + 1 < pageCount && (
                <Box sx={{ p: "3px 8px" }}>{currentPage + 2}</Box>
              )}
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              disabled={currentPage + 1 >= pageCount}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  color: currentPage + 1 >= pageCount ? "disabled" : "black",
                }}
              >
                Next
              </Typography>
              <NavigateNextRoundedIcon
                fontSize="medium"
                sx={{
                  color: currentPage + 1 >= pageCount ? "disabled" : "black",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CustomePagination;
