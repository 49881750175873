export const FETCH_ALL_ITEMS_REQUEST = "FETCH_ALL_ITEMS_REQUEST";
export const FETCH_ALL_ITEMS_SUCCESS = "FETCH_ALL_ITEMS_SUCCESS";
export const FETCH_ALL_ITEMS_FAIL = "FETCH_ALL_ITEMS_FAIL";

export const FETCH_CATEGORY_ITEMS_REQUEST = "FETCH_CATEGORY_ITEMS_REQUEST";
export const FETCH_CATEGORY_ITEMS_SUCCESS = "FETCH_CATEGORY_ITEMS_SUCCESS";
export const FETCH_CATEGORY_ITEMS_FAIL = "FETCH_CATEGORY_ITEMS_FAIL";

export const FETCH_ITEM_REQUEST = "FETCH_ITEM_REQUEST";
export const FETCH_ITEM_SUCCESS = "FETCH_ITEM_SUCCESS";
export const FETCH_ITEM_FAIL = "FETCH_ITEM_FAIL";

export const ADD_ITEM_REQUEST = "ADD_ITEM_REQUEST";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAIL = "ADD_ITEM_FAIL";

export const EDIT_ITEM_REQUEST = "EDIT_ITEM_REQUEST";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAIL = "EDIT_ITEM_FAIL";

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

export const FETCH_ALL_CATEGORIES_REQUEST = "FETCH_ALL_CATEGORIES_REQUEST";
export const FETCH_ALL_CATEGORIES_SUCCESS = "FETCH_ALL_CATEGORIES_SUCCESS";
export const FETCH_ALL_CATEGORIES_FAIL = "FETCH_ALL_CATEGORIES_FAIL";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

export const FETCH_PAGINATED_ITEMS_REQUEST = "FETCH_PAGINATED_ITEMS_REQUEST";
export const FETCH_PAGINATED_ITEMS_SUCCESS = "FETCH_PAGINATED_ITEMS_SUCCESS";
export const FETCH_PAGINATED_ITEMS_FAIL = "FETCH_PAGINATED_ITEMS_FAIL";

export const fetchPaginatedItems = (data)=>{
  return {type: FETCH_PAGINATED_ITEMS_REQUEST, payload: data}
}

export const fetchSingleItemRequest = (data) => {
  return { type: FETCH_ITEM_REQUEST, payload: data };
};

export const fetchHomeItems = () => {
  return { type: FETCH_ALL_ITEMS_REQUEST };
};

export const fetchCategotyItems = (id) => {
  return { type: FETCH_CATEGORY_ITEMS_REQUEST, payload: id };
};

export const addNewItem = (data) => {
  return { type: ADD_ITEM_REQUEST, payload: data };
};

export const editItem = (data) => {
  return { type: EDIT_ITEM_REQUEST, payload: data };
};

export const deleteItem = (id) => {
  return { type: DELETE_ITEM_REQUEST, payload: id };
};

export const getAllCategories = () => {
  return { type: FETCH_ALL_CATEGORIES_REQUEST };
};

export const addNewCategory = (data) => {
  return { type: ADD_CATEGORY_REQUEST, payload: data };
};

export const deleteCategory = (id) => {
  return { type: DELETE_CATEGORY_REQUEST, payload: id };
};
