import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { changePasswordAction } from "../../Redux/Actions/AuthAction";

const REQUIRED = "This field is required";
const PASSWORD = "Password must have at least 4 characters";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [isVisibility, setIsVisibility] = useState(false);
  const { cellNumber } = useSelector((state) => state.Auth.user);

  const { handleSubmit, control, watch } = useForm({ mode: "onSubmit" });
  const password = watch("newPassword", "");

  const handleUpdatePassword = (data) => {
    dispatch(changePasswordAction({ ...data, cellNumber }));
  };

  return (
    <Card sx={{ borderRadius: "8px" }}>
      <CardContent className="profile-content">
        <Typography variant="h2" color="primary">
          Change Password
        </Typography>
        <br />
        <Box sx={{ display: "flex", width: "300px" }}>
          {isVisibility ? (
            <Visibility
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
              onClick={() => setIsVisibility(false)}
            />
          ) : (
            <VisibilityOff
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
              onClick={() => setIsVisibility(true)}
            />
          )}
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error ? error.message : null}
                error={!!error}
                variant="standard"
                placeholder="Current Password"
                type={isVisibility ? "text" : "password"}
                sx={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: REQUIRED,
              minLength: {
                value: 4,
                message: PASSWORD,
              },
            }}
          />
        </Box>
        <br />
        <Box sx={{ display: "flex", width: "300px" }}>
          {isVisibility ? (
            <Visibility
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
              onClick={() => setIsVisibility(false)}
            />
          ) : (
            <VisibilityOff
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
              onClick={() => setIsVisibility(true)}
            />
          )}
          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error ? error.message : null}
                error={!!error}
                variant="standard"
                placeholder="New Password"
                type={isVisibility ? "text" : "password"}
                sx={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: REQUIRED,
              minLength: {
                value: 4,
                message: PASSWORD,
              },
            }}
          />
        </Box>
        <br />
        <Box sx={{ display: "flex", width: "300px" }}>
          {isVisibility ? (
            <Visibility
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
              onClick={() => setIsVisibility(false)}
            />
          ) : (
            <VisibilityOff
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
              onClick={() => setIsVisibility(true)}
            />
          )}
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error ? error.message : null}
                error={!!error}
                variant="standard"
                placeholder="Confirm Password"
                type={isVisibility ? "text" : "password"}
                sx={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: REQUIRED,
              validate: (value) =>
                value === password || "Passwords do not match",
            }}
          />
        </Box>
        <br />
        <Button onClick={handleSubmit(handleUpdatePassword)}>Update</Button>
      </CardContent>
    </Card>
  );
};

export default ChangePassword;
