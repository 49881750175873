import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { CancelRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { deleteCategory } from "../../Redux/Actions/ItemActon";

const DeleteCategoryModal = ({categoryId, isOpen, handleClose }) => {
  const dispatch = useDispatch();

  const removeCategory = () => {
    dispatch(deleteCategory(categoryId));
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ sx: { bgcolor: "#fff" } }}
    >
      <DialogTitle sx={{ width: "180px" }}>
        <br />
        <Typography variant="h3">Delete Category</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelRounded color="error" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          Are you sure you want to delete this category?
        </Typography>
        <Button className="add-button" onClick={removeCategory}>
          <Typography variant="h3">Delete</Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteCategoryModal;
