import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_ITEM_FAIL,
  ADD_ITEM_REQUEST,
  ADD_ITEM_SUCCESS,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  EDIT_ITEM_FAIL,
  EDIT_ITEM_REQUEST,
  EDIT_ITEM_SUCCESS,
  FETCH_ALL_CATEGORIES_FAIL,
  FETCH_ALL_CATEGORIES_REQUEST,
  FETCH_ALL_CATEGORIES_SUCCESS,
  FETCH_ALL_ITEMS_FAIL,
  FETCH_ALL_ITEMS_REQUEST,
  FETCH_ALL_ITEMS_SUCCESS,
  FETCH_CATEGORY_ITEMS_FAIL,
  FETCH_CATEGORY_ITEMS_REQUEST,
  FETCH_CATEGORY_ITEMS_SUCCESS,
  FETCH_ITEM_FAIL,
  FETCH_ITEM_REQUEST,
  FETCH_ITEM_SUCCESS,
  FETCH_PAGINATED_ITEMS_FAIL,
  FETCH_PAGINATED_ITEMS_REQUEST,
  FETCH_PAGINATED_ITEMS_SUCCESS,
} from "../Actions/ItemActon";
import axios from "axios";
import { TOAST_SHOW } from "../Actions/ToastAction";
import api from "../../utils/Axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function* fetchItemsSaga(action) {
  const { skip, limit } = action.payload;
  try {
    const response = yield call(axios.get, `${BASE_URL}/products/items?skip=${skip}&limit=${limit}`);
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_PAGINATED_ITEMS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: FETCH_PAGINATED_ITEMS_FAIL,
    });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* fetchItemSaga(action) {
  try {
    let { itemCode } = action.payload;
    const response = yield call(
      axios.get,
      `${BASE_URL}/products/item/${itemCode}`
    );
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_ITEM_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: FETCH_ITEM_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* fetchCategoryItemsSaga(action) {
  try {
    let categoryId = action.payload;
    const response = yield call(
      axios.get,
      `${BASE_URL}/products/${categoryId}`
    );
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_CATEGORY_ITEMS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: FETCH_CATEGORY_ITEMS_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* fetchHomeItemsSaga(action) {
  try {
    const response = yield call(axios.get, `${BASE_URL}/products/categories`);
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_ALL_ITEMS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: FETCH_ALL_ITEMS_FAIL,
    });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* addItemSaga(action) {
  try {
    const response = yield call(api.post, `/products`, action.payload);
    const { data } = response;
    if (data) {
      yield put({
        type: ADD_ITEM_SUCCESS,
        payload: data,
      });
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: "Item successfully added",
          severity: "success",
        },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: ADD_ITEM_FAIL, payload: error });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* editItemSaga(action) {
  try {
    const response = yield call(
      api.put,
      `/products/${action.payload._id}`,
      action.payload
    );
    const { data } = response;
    if (data) {
      yield put({
        type: EDIT_ITEM_SUCCESS,
        payload: data,
      });
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: "Item successfully edited",
          severity: "success",
        },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: EDIT_ITEM_FAIL, payload: error });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* deleteItemSaga(action) {
  try {
    const response = yield call(
      api.delete,
      `/products/${action.payload}`,
      action.payload
    );
    const { data } = response;
    if (data) {
      yield put({
        type: DELETE_ITEM_SUCCESS,
      });
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: "Item successfully delete",
          severity: "success",
        },
      });
      window.location.href = "/";
    }
  } catch (error) {
    console.log(error);
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* fetchAllCategoriesSaga(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/products/categories-name`
    );
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_ALL_CATEGORIES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: FETCH_ALL_CATEGORIES_FAIL,
    });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* addCategorySaga(action) {
  try {
    const response = yield call(
      api.post,
      `/products/add-category`,
      action.payload
    );
    const { data } = response;
    if (data) {
      yield put({
        type: ADD_CATEGORY_SUCCESS,
        payload: data,
      });
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: "Category successfully added",
          severity: "success",
        },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: ADD_CATEGORY_FAIL, payload: error });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* deleteCategorySaga(action) {
  try {
    const response = yield call(
      api.delete,
      `/products/delete-category/${action.payload}`
    );
    const { data } = response;
    if (data) {
      yield put({
        type: DELETE_CATEGORY_SUCCESS,
      });
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: "Category successfully delete",
          severity: "success",
        },
      });
      window.location.href = "/";
    }
  } catch (error) {
    console.log(error);
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* watchFetchItem() {
  yield takeEvery(FETCH_PAGINATED_ITEMS_REQUEST, fetchItemsSaga);
  yield takeEvery(FETCH_ITEM_REQUEST, fetchItemSaga);
  yield takeEvery(FETCH_CATEGORY_ITEMS_REQUEST, fetchCategoryItemsSaga);
  yield takeEvery(FETCH_ALL_ITEMS_REQUEST, fetchHomeItemsSaga);
  yield takeEvery(ADD_ITEM_REQUEST, addItemSaga);
  yield takeEvery(EDIT_ITEM_REQUEST, editItemSaga);
  yield takeEvery(DELETE_ITEM_REQUEST, deleteItemSaga);
  yield takeEvery(FETCH_ALL_CATEGORIES_REQUEST, fetchAllCategoriesSaga);
  yield takeEvery(ADD_CATEGORY_REQUEST, addCategorySaga);
  yield takeEvery(DELETE_CATEGORY_REQUEST, deleteCategorySaga);
}

export default function* ItemSaga() {
  yield all([watchFetchItem()]);
}
