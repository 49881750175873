import {
  FETCH_CITIES_FAIL,
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
} from "../Actions/AddressAction";

const initialState = {
  cities: [],
  loading: false,
  error: "",
};

export const AddressReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case FETCH_CITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        cities: action.payload,
      };
    case FETCH_CITIES_FAIL:
      return {
        ...state,
        loading: false,
        cities: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
