import { combineReducers } from "redux";
import { ToggleReducer } from "./ToggleReducer";
import { CartReducer } from "./CartReducer";
import { ItemReducer } from "./ItemReducer";
import { AuthReducer } from "./authReducer";
import { ToastReducer } from "./ToastReducer";
import { AddressReducer } from "./AddressReducer";
import { OrderReducer } from "./OrderReducer";

const rootReducer = combineReducers({
  Toggle: ToggleReducer,
  Cart: CartReducer,
  Items: ItemReducer,
  Auth: AuthReducer,
  Toast: ToastReducer,
  Address: AddressReducer,
  Order: OrderReducer,
});

export default rootReducer;
