import { PhoneIphone, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { loginAction } from "../../Redux/Actions/AuthAction";

const REQUIRED = "This field is required";
const PHONE = "Enter a valid phone number.";
const PASSWORD = "Password must have at least 4 characters";
const PHONE_REG = /^03\d{9}$/; // eslint-disable-line

const Login = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm({ mode: "onSubmit" });
  const [isVisibility, setIsVisibility] = useState(false);
  const handleLogin = (data) => {
    dispatch(loginAction(data));
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
      }}
    >
      <Card
        sx={{
          minWidth: {
            xs: "90%",
            sm: "500px",
            md: "500px",
            borderRadius: "10px",
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              className="center"
              color="#fff"
              variant="h2"
              sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
            >
              Sign In
            </Typography>
          }
          component="div"
          sx={{
            background: "#E3B625",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            mt: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "300px",
            }}
          >
            <PhoneIphone sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <Controller
              name="cellNumber"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="03xx xxxxxxx"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                type: "number",
                pattern: {
                  value: PHONE_REG,
                  message: PHONE,
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", width: "300px" }}>
            {isVisibility ? (
              <Visibility
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(false)}
              />
            ) : (
              <VisibilityOff
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(true)}
              />
            )}
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Enter Your Password"
                  type={isVisibility ? "text" : "password"}
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                minLength: {
                  value: 4,
                  message: PASSWORD,
                },
              }}
            />
          </Box>
          <Button
            variant="outlined"
            sx={{ borderRadius: "50px" }}
            onClick={handleSubmit(handleLogin)}
          >
            Sign In
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography>
              <Link
                to="/forgot-password"
                style={{
                  textDecoration: "none",
                  fontWeight: 500,
                  color: "#E3B625",
                }}
              >
                Forgot Password
              </Link>
            </Typography>
            <Typography>
              Don't have account?{" "}
              <Link
                to="/register"
                style={{
                  textDecoration: "none",
                  fontWeight: 500,
                  color: "#E3B625",
                }}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
