import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import "./profile.css";
import { ArrowForwardRounded } from "@mui/icons-material";
import ChangePassword from "../Login/ChangePassword";
import { useSelector } from "react-redux";
import ProfileCard from "./ProfileCard";
import Orders from "../Orders/Orders";

const Profile = () => {
  const params = useParams();
  const { user } = useSelector((state) => state.Auth);

  return (
    <Box className="start">
      <Grid container spacing={{ sm: 0, md: 2 }} className="profile-grid">
        <Grid item xs={12} sm={8} md={3}>
          <Card sx={{ borderRadius: "8px" }}>
            <CardContent>
              <Typography variant="h2" color="primary">
                My Account
              </Typography>
              <br />
              <Link to="/user/profile" className="profile-links">
                <Box className="link-start">
                  <ArrowForwardRounded />{" "}
                  <Typography color="#829dac">Profile</Typography>
                </Box>
              </Link>
              <Link to="/user/orders" className="profile-links">
                <Box className="link-start">
                  <ArrowForwardRounded />{" "}
                  <Typography color="#829dac">Order History</Typography>
                </Box>
              </Link>
              <Link to="/user/change-password" className="profile-links">
                <Box className="link-start">
                  <ArrowForwardRounded />
                  <Typography color="#829dac">Change Password</Typography>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} md={9} pt="20px">
          {params?.action === "profile" && <ProfileCard user={user} />}
          {params?.action === "change-password" && <ChangePassword />}
          {params?.action === "orders" && <Orders />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
