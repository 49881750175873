import {
  Box,
  Card,
  Typography,
  Divider,
  CardMedia,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jsPDF } from "jspdf";

import { useParams } from "react-router-dom";
import { fetchOrderDetails } from "../../Redux/Actions/OrderAction";
import theme from "../../theme";
import { formatDate } from "../../utils/formatDate";

const Order = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { order, loading } = useSelector((state) => state.Order);
  const { user, token } = useSelector((state) => state.Auth);
  useEffect(() => {
    dispatch(fetchOrderDetails(params.orderId));
  }, [params.orderId]);

  const handlePrint = () => {
    let height = 53;
    let totalHeight = height + (order.orderItemsDetail.length - 1) * 3 + 1;
    let pageHeight = totalHeight + 35;

    const doc = new jsPDF({
      format: [80, pageHeight],
    });
    doc.addImage(
      "https://i.ibb.co/FKVRm9Z/Mandi-Mart-1-01.png",
      "JPEG",
      25,
      2,
      30,
      30
    );

    // shop address
    doc.setFontSize(7);
    doc.text("Shop# 13, Fruit & Vegetable Market, Kamoke, Gujranwala", 8, 28);

    // Add customer information
    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Customer Information", 25, 33);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.text(`Name: ${order?.user?.fullName}`, 5, 37);
    doc.text(`Date Time: ${formatDate()}`, 43, 37);
    doc.text(
      `Sector: ${order?.address?.sector}, ${order?.address?.city?.name}`,
      5,
      40
    );
    doc.text(`Phone# : ${order?.user?.cellNumber}`, 43, 40);
    doc.text(`Address: ${order?.address?.address}`, 5, 43);

    // Add table headers
    doc.setFont("helvetica", "bold");
    doc.setLineWidth(0.1);
    doc.line(5, 46, 75, 46);
    doc.text("Item Name", 7, 49);
    // doc.text("QTY", 44, 49);
    // doc.text("Unit Price", 52, 49);
    doc.text("QTY", 40, 49);
    doc.text("Price", 49, 49);
    doc.text("Disc", 58, 49);
    doc.text("Total", 67, 49);
    doc.line(5, 50, 75, 50);

    // Add table rows
    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    order.orderItemsDetail?.forEach((itemDetails, idx) => {
      const discount = Math.floor(
        itemDetails?.quantity *
          ((itemDetails?.product?.price * itemDetails?.product?.discount) / 100)
      );
      doc.text(
        itemDetails?.product?.name.replace(/\([^)]*\)/g, "").trim(),
        7,
        height + idx * 3
      );
      doc.text(`${itemDetails?.quantity}`, 42, height + idx * 3);
      doc.text(`${itemDetails?.product?.price}`, 50, height + idx * 3);
      doc.text(`${discount}`, 59, height + idx * 3);
      doc.text(
        `${(itemDetails?.product?.price * itemDetails?.quantity) - discount}`,
        68,
        height + idx * 3
      );
    });

    // Add totals
    doc.setFontSize(7);
    doc.line(5, totalHeight, 75, totalHeight);
    doc.setFont("helvetica", "bold");
    doc.text("Total Items:", 7, totalHeight + 3);
    doc.text("Total amount:", 44, totalHeight + 3);
    doc.text("Discount:", 44, totalHeight + 7);
    doc.text("Delivery:", 44, totalHeight + 11);
    // doc.text("Packaging:", 44, totalHeight + 15);
    doc.setFont("helvetica", "normal");
    doc.text(`${order?.orderItemsDetail.length}`, 22, totalHeight + 3);
    doc.text(`${order?.amount}`, 68, totalHeight + 3);
    doc.text(`${order?.discountedAmount}`, 68, totalHeight + 7);
    doc.text(`${order?.deliveryCharges}`, 68, totalHeight + 11);
    // doc.text(`${order?.packingCharges}`, 68, totalHeight + 15);
    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Net Total:", 44, totalHeight + 15);
    doc.text(`${order?.totalAmount}`, 68, totalHeight + 15);//19

    //Thanks Note
    doc.setFontSize(7);
    doc.line(5, totalHeight + 17, 75, totalHeight + 17);//21
    doc.setFont("helvetica", "normal");
    doc.text("Thank you for shopping with us!", 23, totalHeight + 20);//24
    doc.text("MandiMart: 0309-4004242, 0300-7449066", 18, totalHeight + 23);//27
    doc.text("www.mandimart.pk", 31, totalHeight + 26);//30
    doc.line(31, totalHeight + 26, 52, totalHeight + 26);//30

    //save
    // doc.autoPrint({ variant: "non-conform" });
    doc.save(`${order._id}.pdf`);
  };

  function handleItems() {
    let height = 18;
    let totalPageHeight = height + (order.orderItemsDetail.length - 1) * 4 + 5;
    const doc2 = new jsPDF({
      format: [80, totalPageHeight < 80 ? 80 : totalPageHeight],
    });

    doc2.setFont("helvetica", "normal");
    doc2.setFontSize(7);
    doc2.text(`Name: ${order?.user?.fullName}`, 5, 5);
    doc2.text(`Date Time: ${formatDate()}`, 43, 5);
    doc2.text(
      `Sector: ${order?.address?.sector}, ${order?.address?.city?.name}`,
      5,
      8
    );
    doc2.text(`Address: ${order?.address?.address}`, 5, 11);
    doc2.line(5, 13, 75, 13);
    doc2.setFontSize(9);
    order.orderItemsDetail?.forEach((itemDetails, idx) => {
      doc2.text(
        itemDetails?.product?.name.replace(/\([^)]*\)/g, "").trim(),
        7,
        height + idx * 4
      );
      doc2.text(`${itemDetails?.quantity}`, 72, height + idx * 4);
    });
    doc2.save(`items-${order._id}.pdf`);
  }

  const columns = [
    {
      field: "id",
      headerName: "Product Detail",
      width: 300,
      flex: 3,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (data) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <CardMedia
              sx={{
                width: { md: "45%", xs: "40%", m370: "60%" },
                aspectRatio: 1 / 0.75,
              }}
              image={data.row.imageUrl}
              title={data.row.itemName}
            />
            <Typography
              sx={{ whiteSpace: "wrap", color: theme.palette.text.primary }}
            >
              {data?.row?.itemName}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      // width: 200,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              color: theme.palette.text.primary,
            }}
          >
            {cellValues.value}
          </Typography>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      // width: 130,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              color: theme.palette.text.primary,
            }}
          >
            {cellValues.value}
          </Typography>
        );
      },
    },
    {
      field: "discount",
      headerName: "Discount",
      // width: 130,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              color: theme.palette.text.primary,
            }}
          >
            {cellValues.value}
          </Typography>
        );
      },
    },
    {
      field: "amount",
      headerName: "Total",
      // width: 140,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (data) => {
        return (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              color: theme.palette.text.primary,
            }}
          >
            {data?.row.price * data?.row?.quantity - data?.row?.discount}
          </Typography>
        );
      },
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        p: "50px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "50px",
      }}
    >
      {!loading && (
        <>
          <Card
            sx={{
              width: "100%",
              borderRadius: "4px",
              maxWidth: "500px",
            }}
          >
            <DataGrid
              disableSelectionOnClick
              columns={columns}
              rows={
                order.orderItemsDetail?.map((itemDetails) => ({
                  id: itemDetails?._id,
                  itemName: itemDetails?.product?.name,
                  imageUrl: itemDetails?.product?.imageUrl,
                  price: itemDetails?.product?.price,
                  quantity: itemDetails?.quantity,
                  discount: Math.floor(
                    itemDetails?.quantity *
                      ((itemDetails?.product?.price *
                        itemDetails?.product?.discount) /
                        100)
                  ),
                  ...itemDetails,
                })) || []
              }
              disableColumnMenu={true}
              autoHeight
              hideFooter
              rowHeight={150}
              sx={{
                width: "100%",
                borderRadius: "4px",
                "&.MuiDataGrid-root": {
                  border: "none",
                  color: theme?.palette.text.disabled,
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F5F5F5",
                  fontSize: 14,
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: 1.5,
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                  maxWidth: "3px",
                  maxHeight: "3px",
                },
              }}
            />
          </Card>
          <Card className="total-card">
            <Box className="title-box">
              <Typography className="total-price" variant="h4">
                Name:
              </Typography>
              <Typography className="total-title" variant="h4">
                {order?.user?.fullName}
              </Typography>
            </Box>
            <Box className="title-box">
              <Typography className="total-price" variant="h4">
                Cell Number:
              </Typography>
              <Typography className="total-title" variant="h4">
                {order?.user?.cellNumber}
              </Typography>
            </Box>
            <Box className="title-box">
              <Typography className="total-price" variant="h4">
                Address:
              </Typography>
              <Typography className="total-title" variant="h4">
                {order?.address?.address}
              </Typography>
            </Box>
            <Box className="title-box">
              <Typography className="total-price" variant="h4">
                Sector:
              </Typography>
              <Typography className="total-title" variant="h4">
                {order?.address?.sector}
              </Typography>
            </Box>
            <Box className="title-box">
              <Typography className="total-price" variant="h4">
                City:
              </Typography>
              <Typography className="total-title" variant="h4">
                {order?.address?.city?.name}
              </Typography>
            </Box>
            <Box className="title-box">
              <Typography className="total-price" variant="h4">
                Placed at:
              </Typography>
              <Typography className="total-title" variant="h4">
                {formatDate(order?.createdAt)}
              </Typography>
            </Box>
            <Box className="title-box">
              <Typography className="total-price" variant="h4">
                Remarks:
              </Typography>
              <Typography className="total-title" variant="h4">
                {order?.remarks}
              </Typography>
            </Box>
          </Card>

          <Card className="total-card">
            <Box className="title-box">
              <Typography className="total-title" variant="h4">
                Subtotal:
              </Typography>
              <Typography className="total-price" variant="h4">
                Rs {order?.amount}
              </Typography>
            </Box>
            <Divider className="divider-line" />
            <Box className="title-box">
              <Typography className="total-title" variant="h4">
                Discount:
              </Typography>
              <Typography className="total-price" variant="h4">
                Rs {order?.discountedAmount}
              </Typography>
            </Box>
            <Divider className="divider-line" />
            <Box className="title-box">
              <Typography className="total-title" variant="h4">
                Delivery Charges:
              </Typography>
              <Typography className="total-price" variant="h4">
                Rs {order?.deliveryCharges}
              </Typography>
            </Box>
            <Divider className="divider-line" />
            <Box className="title-box">
              <Typography className="total-title" variant="h4">
                Packing Charges:
              </Typography>
              <Typography className="total-price" variant="h4">
                Rs {order?.packingCharges}
              </Typography>
            </Box>
            <Divider className="divider-line" />
            <Box className="title-box">
              <Typography className="total-title" variant="h4" color="primary">
                Total:
              </Typography>
              <Typography className="total-price" variant="h4" color="primary">
                Rs {order?.totalAmount}
              </Typography>
            </Box>
            <Divider sx={{ margin: "10px 0px 0px", width: "100%" }} />
          </Card>
          {(user?.type === "admin" || user?.type === "seller") && token && (
            <Box className="center" gap={5}>
              <Button className="cart-button" onClick={handlePrint}>
                Print bill
              </Button>
              <Button className="cart-button" onClick={handleItems}>
                Print Items
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Order;
