export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";

export const addToCartAction = (data) => {
  return { type: ADD_TO_CART, payload: data };
};

export const removeFromCartAction = (data) => {
  return { type: REMOVE_FROM_CART, payload: data };
};

export const clearCartAction = () => {
  return { type: CLEAR_CART };
};
