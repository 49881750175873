import {
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_ITEM_FAIL,
  ADD_ITEM_REQUEST,
  ADD_ITEM_SUCCESS,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  EDIT_ITEM_FAIL,
  EDIT_ITEM_REQUEST,
  EDIT_ITEM_SUCCESS,
  FETCH_ALL_CATEGORIES_FAIL,
  FETCH_ALL_CATEGORIES_REQUEST,
  FETCH_ALL_CATEGORIES_SUCCESS,
  FETCH_ALL_ITEMS_FAIL,
  FETCH_ALL_ITEMS_REQUEST,
  FETCH_ALL_ITEMS_SUCCESS,
  FETCH_CATEGORY_ITEMS_FAIL,
  FETCH_CATEGORY_ITEMS_REQUEST,
  FETCH_CATEGORY_ITEMS_SUCCESS,
  FETCH_ITEM_FAIL,
  FETCH_ITEM_REQUEST,
  FETCH_ITEM_SUCCESS,
  FETCH_PAGINATED_ITEMS_REQUEST,
  FETCH_PAGINATED_ITEMS_SUCCESS,
} from "../Actions/ItemActon";

const initialState = {
  paginatedItems: [],
  items: [],
  item: {},
  categories: [],
  category: [],
  categoriesList: [],
  totalCount: 0,
  loading: false,
  paginatedLoading: false,
  error: "",
};

export const ItemReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case FETCH_PAGINATED_ITEMS_REQUEST:
      return {
        ...state,
        paginatedLoading: true,
      };
    case FETCH_PAGINATED_ITEMS_SUCCESS:
      return {
        ...state,
        paginatedLoading: false,
        paginatedItems: action.payload?.items,
        totalCount: action.payload?.totalCount,
      };
    case FETCH_PAGINATED_ITEMS_REQUEST:
      return {
        ...state,
        paginatedItems:[],
        paginatedLoading: false,
      };
    case FETCH_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ITEM_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    case FETCH_ITEM_FAIL:
      return {
        ...state,
        item: {},
        loading: false,
      };
    case FETCH_CATEGORY_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATEGORY_ITEMS_SUCCESS:
      return {
        ...state,
        category: action.payload,
        loading: false,
      };
    case FETCH_CATEGORY_ITEMS_FAIL:
      return {
        ...state,
        category: [],
        loading: false,
      };
    case FETCH_ALL_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_ITEMS_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case FETCH_ALL_ITEMS_FAIL:
      return {
        ...state,
        categories: [],
        loading: false,
      };
    case ADD_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ITEM_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    case ADD_ITEM_FAIL:
      return {
        ...state,
        item: {},
        loading: false,
        error: action.payload,
      };
    case EDIT_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_ITEM_SUCCESS:
      return {
        ...state,
        item: action.payload,
        paginatedItems: state.paginatedItems?.map((item) =>{
          if(item?._id === action.payload._id){
            return action.payload
          }
          return item
        }),
        loading: false,
      };
    case EDIT_ITEM_FAIL:
      return {
        ...state,
        item: {},
        loading: false,
      };
    case DELETE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        item: {},
        loading: false,
      };
    case FETCH_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesList: action.payload,
        loading: false,
      };
    case FETCH_ALL_CATEGORIES_FAIL:
      return {
        ...state,
        // categoriesList: [],
        loading: false,
      };
    case ADD_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categoriesList: [...state.categoriesList, action.payload],
        loading: false,
      };
    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoriesList: state.categoriesList.filter(
          (category) => category._id !== action.payload
        ),
        loading: false,
      };
    default:
      return state;
  }
};
