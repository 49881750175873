export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";

export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAIL = "FETCH_ORDER_FAIL";

export const CUSTOMER_ORDERS_REQUEST = "CUSTOMER_ORDERS_REQUEST";
export const CUSTOMER_ORDERS_SUCCESS = "CUSTOMER_ORDERS_SUCCESS";
export const CUSTOMER_ORDERS_FAIL = "CUSTOMER_ORDERS_FAIL";

export const FETCH_ALL_ORDERS_REQUEST = "FETCH_ALL_ORDERS_REQUEST";
export const FETCH_ALL_ORDERS_SUCCESS = "FETCH_ALL_ORDERS_SUCCESS";
export const FETCH_ALL_ORDERS_FAIL = "FETCH_ALL_ORDERS_FAIL";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const createOrderAction = (data) => {
  return { type: CREATE_ORDER_REQUEST, payload: data };
};

export const customerOrdersAction = (data) => {
  return { type: CUSTOMER_ORDERS_REQUEST, payload: data };
};

export const fetchOrderDetails = (id) => {
  return { type: FETCH_ORDER_REQUEST, payload: id };
};

export const fetchAllOrdersDetail = (data) => {
  return { type: FETCH_ALL_ORDERS_REQUEST, payload: data };
};

export const updateOrderAction = (data) => {
  return { type: UPDATE_ORDER_REQUEST, payload: data };
};
