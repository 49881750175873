import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import OrderSummary from "./OrderSummary";
import { useDispatch, useSelector } from "react-redux";
import ProfileInfo from "./ProfileInfo";
import AddressInfo from "./AddressInfo";
import SelectedAddress from "./SelectedAddress";
import { calculateTotal } from "../../utils/Calculations";
import { PAYMENT_METHOD, RETURN_TYPE, STATUS } from "../../utils/Enums";
import { createOrderAction } from "../../Redux/Actions/OrderAction";
import { useForm } from "react-hook-form";
import { signupAction } from "../../Redux/Actions/AuthAction";

const CheckOut = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.Auth);
  const { cart } = useSelector((state) => state.Cart);
  const city = useMemo(
    () => user?.address?.find((obj) => obj.isSelected)?.city?.name,
    [user?.address?.find((obj) => obj.isSelected)?.city?.name]
  );
  const [remarks, setRemarks] = useState("");
  const { handleSubmit, control, watch } = useForm({ mode: "onSubmit" });
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState({
    street: "",
    sector: " ",
    cityId: " ",
    label: "",
    streetError: false,
    sectorError: false,
    cityError: false,
    labelError: false,
  });
  const {
    deliveryCharges,
    packingTotal,
    grandTotal,
    amount,
    discountedAmount,
  } = useMemo(() => calculateTotal(cart, city), [cart, city]);

  const handleRegister = (data) => {
    handleAddAddress(data);
  };
  const handleAddressError = (name) => {
    setAddress((address) => ({ ...address, [name]: true }));
  };

  const handleAddAddress = (data) => {
    setIsLoading(true);
    if (!address.cityId.trim()) {
      handleAddressError("cityError");
      return;
    }
    if (!address.sector.trim()) {
      handleAddressError("sectorError");
      return;
    }
    if (!address.street.trim()) {
      handleAddressError("streetError");
      return;
    }
    const addressPayload = {
      title: "Home",
      address: address.street,
      sector: address.sector,
      city: address.cityId,
      isSelected: true,
    };
    const orderPayload = {
      amount: amount,
      totalAmount: grandTotal,
      discountedAmount: discountedAmount,
      packingCharges: packingTotal,
      deliveryCharges: deliveryCharges,
      status: STATUS.PROCESSING,
      paymentMethod: PAYMENT_METHOD.CASH_ON_DELIVERY,
      returnType: RETURN_TYPE.NOT_RETURNED,
      remarks,
      orderItemsDetail: cart?.map((cartItem) => ({
        product: cartItem.item,
        quantity: cartItem.quantity,
      })),
    };
    dispatch(
      signupAction({
        ...data,
        isCheckout: true,
        address: addressPayload,
        orderDetails: orderPayload,
      })
    );
  };

  const handlePlaceOrder = () => {
    setIsLoading(true);
    const payload = {
      amount: amount,
      totalAmount: grandTotal,
      discountedAmount: discountedAmount,
      packingCharges: packingTotal,
      deliveryCharges: deliveryCharges,
      status: STATUS.PROCESSING,
      paymentMethod: PAYMENT_METHOD.CASH_ON_DELIVERY,
      returnType: RETURN_TYPE.NOT_RETURNED,
      address: user?.address?.find((obj) => obj.isSelected)?._id,
      user: user?._id,
      remarks,
      orderItemsDetail: cart?.map((cartItem) => ({
        product: cartItem.item,
        quantity: cartItem.quantity,
      })),
    };
    dispatch(createOrderAction(payload));
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Grid
        container
        spacing={{ xs: "16px", sm: "16px", md: 2 }}
        sx={{
          p: "10px",
          maxWidth: "1200px",
          width: "100%",
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Box display="flex" gap="16px" flexDirection="column">
            <OrderSummary selectedItems={cart} city={city} />
            <Card className="order-remarks">
              <Typography variant="h2" color="primary">
                Order Remarks
              </Typography>
              <TextField
                multiline
                placeholder={`For example: "deliver order between 3pm to 4pm."`}
                fullWidth
                rows={4}
                value={remarks}
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              />
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box display="flex" gap="16px" flexDirection="column">
            {!token ? (
              <>
                <ProfileInfo
                  control={control}
                  watch={watch}
                  address={address}
                  setAddress={setAddress}
                />
              </>
            ) : user?.address?.length ? (
              <SelectedAddress />
            ) : (
              <AddressInfo />
            )}
            <Card className="order-remarks">
              <Typography variant="h2" color="primary">
                Payment Method
              </Typography>
              <FormControlLabel
                value="cash on delivery"
                control={<Radio checked />}
                label={<Typography variant="h6">Cash on Delivery</Typography>}
              />
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} className="center">
          {token ? (
            <Button
              sx={{ borderRadius: "50px", mb: "40px" }}
              onClick={handlePlaceOrder}
              disabled={isLoading || !user?.address?.length}
            >
              Place Order
            </Button>
          ) : (
            <Button
              disabled={isLoading}
              sx={{ borderRadius: "50px", mb: "40px" }}
              onClick={handleSubmit(handleRegister)}
            >
              Create Account and Proceed
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckOut;
