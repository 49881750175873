import { SIDEBAR_HIDE, SIDEBAR_SHOW } from "../Actions/ToggleAction";

const initialState = {
  sideBar: false,
};

export const ToggleReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SIDEBAR_SHOW:
      return {
        ...state,
        sideBar: true,
      };
    case SIDEBAR_HIDE:
      return {
        ...state,
        sideBar: false,
      };
    default:
      return state;
  }
};
