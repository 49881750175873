import React, { useEffect } from "react";
import Category from "../../Components/SharedComponents/Category";
import "./home.css";
import { Box, Grid, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeItems } from "../../Redux/Actions/ItemActon";
import ImageSlider from "../../Components/ImageSlider/ImageSlider";
import Headline from "../../Components/Headline/Headline";

const Home = () => {
  const { loading, categories } = useSelector((state) => state?.Items);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHomeItems());
  }, [dispatch]);

  return (
    <Box >
      <Headline />
      <ImageSlider />
      {loading ? (
        <Box className="main-wrapper">
          <Skeleton
            variant="rectangular"
            sx={{
              width: "40%",
              height: "30px",
              mb: "10px",
              borderRadius: "4px",
            }}
          />
          <Grid container spacing={2}>
            {[1, 2, 3, 4].map((_, index) => (
              <Grid
                key={index}
                item
                xs={12}
                m370={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                className="items-wrapper"
              >
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={230}
                  sx={{
                    width: "100%",
                    height: 230,
                    borderRadius: "4px",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        categories?.map((category, index) => (
          <Category category={category} key={index} />
        ))
      )}
    </Box>
  );
};

export default Home;
