import React from "react";

const CartIcon = () => {
    return (
        <svg
            viewBox="0 0 512.008 512.008"
            style={{ width: "15rem", height: "15rem" }}
        >
            <path
                style={{ fill: '#E3B625' }}
                d="M508.288,85.764c-3.04,-3.648 -7.552,-5.76 -12.288,-5.76H107.52c-4.96,0 -9.664,2.304 -12.704,6.24c-3.008,3.968 -4.032,9.088 -2.752,13.888l51.2,191.36c1.888,7.04 8.224,11.872 15.456,11.872c0.256,0 0.544,0 0.8,-0.032l305.28,-15.36c7.392,-0.384 13.6,-5.792 14.944,-13.088l32,-176C512.608,94.18 511.328,89.38 508.288,85.764z"
            ></path>
            <path
                d="M400,240.004c-8.832,0 -16,-7.168 -16,-16v-64c0,-8.832 7.168,-16 16,-16s16,7.168 16,16v64C416,232.836 408.832,240.004 400,240.004z"
                style={{ fill: '#fff' }}
            ></path>
            <path
                d="M304,240.004c-8.832,0 -16,-7.168 -16,-16v-64c0,-8.832 7.168,-16 16,-16s16,7.168 16,16v64C320,232.836 312.832,240.004 304,240.004z"
                style={{ fill: '#fff' }}
            ></path >
            <path
                d="M208,240.004c-8.832,0 -16,-7.168 -16,-16v-64c0,-8.832 7.168,-16 16,-16s16,7.168 16,16v64C224,232.836 216.832,240.004 208,240.004z"
                style={{ fill: '#fff' }}
            ></path >
            <path
                d="M208,448.004m-48,0a48,48 0,1 1,96 0a48,48 0,1 1,-96 0"
                style={{ fill: 'rgb(69, 90, 100)' }}
            ></path>
            <path
                d="M400,448.004m-48,0a48,48 0,1 1,96 0a48,48 0,1 1,-96 0"
                style={{ fill: 'rgb(69, 90, 100)' }}
            ></path>
            <path
                d="M432,368.004H176c-7.264,0 -13.6,-4.864 -15.456,-11.872L78.272,48.004H16c-8.832,0 -16,-7.168 -16,-16s7.168,-16 16,-16h74.56c7.264,0 13.6,4.864 15.456,11.872l82.272,308.128H432c8.832,0 16,7.168 16,16S440.832,368.004 432,368.004z"
                style={{ fill: 'rgb(69, 90, 100)' }}
            ></path>
        </svg >
    );
};

export default CartIcon;
