import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { TOAST_SHOW } from "../Actions/ToastAction";
// import api from "../../utils/Axios";
import {
  ADD_NEW_ADDRESS,
  FETCH_CITIES_FAIL,
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
  UPDATE_DEFAULT_ADDRESS,
} from "../Actions/AddressAction";
import { FETCH_USER_REQUEST } from "../Actions/AuthAction";
import { CREATE_ORDER_REQUEST } from "../Actions/OrderAction";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function* fetchCitiesSaga(action) {
  try {
    const response = yield call(axios.get, `${BASE_URL}/address/city`);
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_CITIES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: FETCH_CITIES_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* updateDefaultAddressSaga(action) {
  try {
    const response = yield call(
      axios.put,
      `${BASE_URL}/address/${action.payload}`,
      { isSelected: true }
    );
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_USER_REQUEST,
        payload: data.user,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: FETCH_CITIES_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* addNewAddressSaga(action) {
  const { isCheckout, orderDetails, ...addressData } = action.payload;
  try {
    const response = yield call(axios.post, `${BASE_URL}/address`, addressData);
    const { data } = response;
    if (data) {
      if (isCheckout) {
        yield put({
          type: CREATE_ORDER_REQUEST,
          payload: { ...orderDetails, address: data._id },
        });
      } else {
        yield put({
          type: FETCH_USER_REQUEST,
          payload: data.user,
        });
      }
    }
  } catch (error) {
    console.log(error);
    yield put({ type: FETCH_CITIES_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* watchFetchAddress() {
  yield takeEvery(FETCH_CITIES_REQUEST, fetchCitiesSaga);
  yield takeEvery(UPDATE_DEFAULT_ADDRESS, updateDefaultAddressSaga);
  yield takeEvery(ADD_NEW_ADDRESS, addNewAddressSaga);
}

export default function* AddressSaga() {
  yield all([watchFetchAddress()]);
}
