import { Avatar, Card, CardContent, Typography } from "@mui/material";
import React from "react";

const ProfileCard = ({ user }) => {
  const { fullName, cellNumber, address } = user;
  return (
    <>
      <Card sx={{ borderRadius: "8px" }}>
        <CardContent className="profile-content">
          <Avatar
            alt={fullName}
            src="/"
            className="item-image profile-avatar"
          />
          <br />
          <Typography variant="h2" color="primary">
            {fullName}
          </Typography>
          <Typography variant="body2">Mobile: {cellNumber}</Typography>
          {address.length ? (
            address
              ?.filter((obj) => obj.isSelected)
              .map((obj) => (
                <>
                  <Typography variant="body2">{obj?.address}</Typography>
                  <Typography variant="body2">Sector: {obj?.sector}</Typography>
                  <Typography variant="body2">
                    City: {obj?.city?.name}
                  </Typography>
                </>
              ))
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ProfileCard;
