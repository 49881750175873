export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAIL = "SIGN_UP_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const LOG_OUT = "LOG_OUT";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const signupAction = (data) => {
  return { type: SIGN_UP_REQUEST, payload: data };
};

export const loginAction = (data) => {
  return { type: LOGIN_REQUEST, payload: data };
};

export const logoutAction = () => {
  return { type: LOG_OUT };
};

export const changePasswordAction = (data) => {
  return { type: CHANGE_PASSWORD, payload: data };
};

export const fetchUserAction = (id) => {
  return { type: FETCH_USER_REQUEST, payload: id };
};
