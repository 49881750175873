import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import {
  AddCircleRounded,
  CancelRounded,
  RemoveCircleRounded,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addToCartAction } from "../../Redux/Actions/CartAction";
import { ToastAction } from "../../Redux/Actions/ToastAction";

const AddToCartModal = ({ item, isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);

  const handleIncrement = () => {
    if (count < 10) setCount((count) => count + 1);
  };

  const handleDecrement = () => {
    if (count > 1) setCount((count) => count - 1);
  };

  const handleAddToCart = () => {
    const payload = { item: item, quantity: count };
    dispatch(addToCartAction(payload));
    dispatch(
      ToastAction({
        message: `${item?.name} added successfully`,
        severity: "success",
      })
    );
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ sx: { bgcolor: "#fff" } }}
    >
      <DialogTitle sx={{ width: "180px" }}>
        <br />
        <Typography variant="h6">
          Enter Quantity for &nbsp;
          <Typography color={"primary"} sx={{ display: "inline" }} variant="h6">
            {item?.name}
          </Typography>{" "}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelRounded color="error" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className="counter-box">
          <Box className="dec-box">
            <IconButton onClick={handleDecrement}>
              <RemoveCircleRounded color="primary" />
            </IconButton>
          </Box>
          <Box className="num-box">{count}</Box>
          <Box className="inc-box">
            <IconButton onClick={handleIncrement}>
              <AddCircleRounded color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Button className="add-button" onClick={handleAddToCart}>
          <Typography variant="h3">Add</Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AddToCartModal;
