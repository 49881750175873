import { Card, CardContent } from "@mui/material";
import React from "react";

function ContactUs() {
  return (
    <div style={{ maxWidth: "800px", margin: "20px auto" }}>
      <Card
        sx={{
          borderRadius: "8px",
          m: "20px",
          p: { sm: "20px", md: "40px" },
          maxWidth: "1000px",
        }}
      >
        <CardContent className="profile-content">
          <h1 style={{ textAlign: "center" }}>Contact Us</h1>
          <div className="contact-info">
            <p style={{ marginBottom: "10px" }}>
              <strong>Visit Us:</strong>
              <br />
              Shop number 13, New Fruit and Vegetable Market, Main GT Road
              <br />
              City Kamoke, Gujranwala
            </p>
            <p style={{ marginBottom: "10px" }}>
              <strong>Contact Information:</strong>
              <br />
              Mobile: <a href="tel:03094004242">0309-4004242</a>
              <br />
              Mobile: <a href="tel:03007449066">0300-7449066</a>
            </p>
            <p style={{ marginBottom: "10px" }}>
              <strong>Operating Hours:</strong>
              <br />
              Monday to Sunday: 08:00 AM to 05:00 PM
            </p>
            <p style={{ marginBottom: "10px" }}>
              <strong>Order Hours:</strong>
              <br />
              Dear customers, Mandi Mart will be taking orders from 01:00 AM to 11:00 AM daily and delivering them within 2 hours to your home. Orders received after 11:00 AM will be delivered the next day to ensure quality during these weather conditions. Your cooperation is appreciated, as always.
            </p>
            <p style={{ marginBottom: "10px" }}>
              <strong>Customer Support:</strong>
              <br />
              For any assistance or inquiries, please contact our customer
              support team via phone during our operating hours or email us at{" "}
              <a href="mailto:sabzifruitmandionline@gmail.com">sabzifruitmandionline@gmail.com</a>.
            </p>
            <p>
              <strong>Feedback:</strong>
              <br />
              We value your feedback and suggestions. If you have any comments
              or suggestions to help us improve our services, please feel free
              to share them with us. Your input is essential in helping us
              enhance your shopping experience at Mandi Mart.
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default ContactUs;
