import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideBarAction } from "../../Redux/Actions/ToggleAction";
import CloseIcon from "@mui/icons-material/Close";
import "./sideBar.css";
import { Link, useNavigate } from "react-router-dom";
import { DeleteForever } from "@mui/icons-material";
import DeleteCategoryModal from "../SharedComponents/DeleteCategoryModal";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryId, setCategoryId] = useState(false);
  const { user, token } = useSelector((state) => state.Auth);
  const { categoriesList } = useSelector((state) => state?.Items);
  const isSideBar = useSelector((state) => state.Toggle.sideBar);
  const handleCloseSidebar = () => {
    dispatch(toggleSideBarAction(false));
  };

  return (
    <>
      <DeleteCategoryModal
        categoryId={categoryId}
        isOpen={!!categoryId}
        handleClose={() => setCategoryId(false)}
      />
      <Drawer
        anchor={"left"}
        sx={{
          width: "250px",
          border: "none",
        }}
        // variant="persistent"
        open={isSideBar}
        onClose={handleCloseSidebar}
      >
        <Box className="side-bar">
          <Box
            className="close-box"
            sx={{ boxShadow: 3, height: { xs: "59px", sm: "64px" } }}
          >
            <IconButton onClick={handleCloseSidebar}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Box>
            <Link to={"/"} className="font" onClick={handleCloseSidebar}>
              Home
            </Link>
            <Link to={"/about"} className="font" onClick={handleCloseSidebar}>
              About us
            </Link>
            {categoriesList?.map((category, index) => (
              <div
                key={index}
                className="font"
                onClick={() => {
                  navigate(
                    `/${category.name
                      .toLowerCase()
                      .trim()
                      .replaceAll(" ", "-")}/${category._id}`
                  );
                  handleCloseSidebar();
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>{category?.name}</Typography>
                {user?.type === "admin" || user?.type === "seller" ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setCategoryId(category._id);
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                ) : null}
              </div>
            ))}
          </Box>
          <Divider />
          {(user?.type === "admin" || user?.type === "seller") && token && (
            <>
              <Link
                className="font"
                to={"/add-category"}
                onClick={handleCloseSidebar}
              >
                {" "}
                Add New Category
              </Link>
              <Link
                to={"/add-item"}
                className="font"
                onClick={handleCloseSidebar}
              >
                Add New Item
              </Link>
              <Link
                to={"/orders"}
                className="font"
                onClick={handleCloseSidebar}
              >
                All Orders
              </Link>
              <Link
                to={"/update-rates"}
                className="font"
                onClick={handleCloseSidebar}
              >
                Update Rates
              </Link>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default SideBar;
