import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "./common.css";
import ItemCard from "./ItemCard";
import { Link } from "react-router-dom";

const Category = ({ category }) => {
  return (
    <Box className="main-wrapper">
      <Link
        style={{
          textDecoration: "none",
        }}
        to={`/${category?.name?.toLowerCase().trim().replaceAll(" ", "-")}/${
          category._id
        }`}
      >
        <Typography variant="h2" color={"#B52B2B"}>
          {category?.name}
        </Typography>
      </Link>
      <Grid container spacing={2}>
        {category?.items?.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            m340={6}
            m370={6}
            sm={4}
            md={4}
            lg={3}
            xl={2}
            className="items-wrapper"
          >
            <ItemCard item={item} category={category?.name} />
          </Grid>
        ))}
      </Grid>
      <Link
        style={{
          display: "block",
          textAlign: "center",
          paddingTop: "20px",
          fontWeight: 700,
          color:"#B52B2B"
        }}
        to={`/${category?.name?.toLowerCase().trim().replaceAll(" ", "-")}/${
          category._id
        }`}
        variant="h2"
        color={"primary"}
      >
        See more
      </Link>
    </Box>
  );
};

export default Category;
