import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [isVisibility, setIsVisibility] = useState(false);

  const handleSubmit = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
      }}
    >
      <Card
        sx={{
          minWidth: {
            xs: "90%",
            sm: "500px",
            md: "500px",
            borderRadius: "10px",
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              className="center"
              color="#fff"
              variant="h2"
              sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
            >
              Sign Up
            </Typography>
          }
          component="div"
          sx={{
            background: "#E3B625",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            mt: "20px",
          }}
        >
          <Box sx={{ display: "flex", width: "300px" }}>
            {isVisibility ? (
              <Visibility
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(false)}
              />
            ) : (
              <VisibilityOff
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(true)}
              />
            )}
            <TextField
              variant="standard"
              placeholder="Enter Your Password"
              type={isVisibility ? "text" : "password"}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ display: "flex", width: "300px" }}>
            {isVisibility ? (
              <Visibility
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(false)}
              />
            ) : (
              <VisibilityOff
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(true)}
              />
            )}
            <TextField
              variant="standard"
              placeholder="Confirm Password"
              type={isVisibility ? "text" : "password"}
              sx={{ width: "100%" }}
            />
          </Box>
          <Button sx={{ borderRadius: "50px" }} onClick={handleSubmit}>
            Submit
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ResetPassword;
