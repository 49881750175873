import {
  PermIdentity,
  PhoneIphone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { signupAction } from "../../Redux/Actions/AuthAction";

const REQUIRED = "This field is required";
const NAME = "Name should be less than 50 characters long.";
const NAME_TYPE = "Name must contain alphabets only.";
const PHONE = "Enter a valid phone number.";
const PASSWORD = "Password must have at least 4 characters";
const NAME_REG = /^[A-Za-z\s]+$/;
const PHONE_REG = /^03\d{9}$/; // eslint-disable-line

const SignUp = () => {
  const dispatch = useDispatch();
  const [isVisibility, setIsVisibility] = useState(false);

  const handleRegister = (data) => {
    dispatch(signupAction(data));
  };

  const { handleSubmit, control, watch } = useForm({ mode: "onSubmit" });
  const password = watch("password", "");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
      }}
    >
      <Card
        sx={{
          minWidth: {
            xs: "90%",
            sm: "500px",
            md: "500px",
            borderRadius: "10px",
          },
        }}
      >
        <CardHeader
          title={
            <Typography
              className="center"
              color="#fff"
              variant="h2"
              sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
            >
              Sign Up
            </Typography>
          }
          component="div"
          sx={{
            background: "#E3B625",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            mt: "20px",
          }}
        >
          <Box sx={{ display: "flex", width: "300px" }}>
            <PermIdentity sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <Controller
              name="fullName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Your Full Name"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                maxLength: {
                  value: 50,
                  message: NAME,
                },
                pattern: {
                  value: NAME_REG,
                  message: NAME_TYPE,
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "300px",
            }}
          >
            <PhoneIphone sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <Controller
              name="cellNumber"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="03xx xxxxxxx"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                type: "number",
                pattern: {
                  value: PHONE_REG,
                  message: PHONE,
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", width: "300px" }}>
            {isVisibility ? (
              <Visibility
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(false)}
              />
            ) : (
              <VisibilityOff
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(true)}
              />
            )}
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Enter Your Password"
                  type={isVisibility ? "text" : "password"}
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                minLength: {
                  value: 4,
                  message: PASSWORD,
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", width: "300px" }}>
            {isVisibility ? (
              <Visibility
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(false)}
              />
            ) : (
              <VisibilityOff
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(true)}
              />
            )}
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Confirm Password"
                  type={isVisibility ? "text" : "password"}
                  sx={{ width: "100%" }}
                  name="confirmPassword"
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                validate: (value) =>
                  value === password || "Passwords do not match",
              }}
            />
          </Box>
          <Button
            sx={{ borderRadius: "50px" }}
            onClick={handleSubmit(handleRegister)}
          >
            Create Account
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography>
              Already have an account?{" "}
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  fontWeight: 500,
                  color: "#E3B625",
                }}
              >
                Sign In
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SignUp;
