import {
  FETCH_USER_FAIL,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOG_OUT,
  SIGN_UP_FAIL,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
} from "../Actions/AuthAction";

const initialState = {
  token: "",
  user: {},
  loading: false,
  error: "",
};

export const AuthReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SIGN_UP_REQUEST:
      return {
        ...state,
        loading: true,
        token: "",
        message: "",
        user: {},
      };
    case SIGN_UP_SUCCESS:
      localStorage.setItem("token", action.payload?.token);
      return {
        ...state,
        loading: false,
        token: action.payload?.token,
        message: "",
        user: action.payload?.user,
      };
    case SIGN_UP_FAIL:
      return {
        ...state,
        loading: false,
        token: "",
        message: action.payload,
        user: {},
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        token: "",
        message: "",
        user: {},
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload?.token);
      return {
        ...state,
        loading: false,
        token: action.payload?.token,
        message: "",
        user: action.payload?.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        token: "",
        message: action.payload,
        user: {},
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        user: action.payload,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case LOG_OUT:
      localStorage.removeItem("token");
      return {
        ...state,
        loading: false,
        token: "",
        message: "",
        user: {},
      };
    default:
      return state;
  }
};
