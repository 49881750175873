import React from "react";
import "./cart.css";
import { Box, Button, Grid } from "@mui/material";
import CartItem from "./CartItem";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TotalCart from "./TotalCart";
import EmptyCart from "./EmptyCart";

const Cart = () => {
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.Cart.cart);
  return (
    <>
      {cartItems?.length ? (
        <Grid
          container
          spacing={3}
          sx={{
            p: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Grid item xs={12} sm={8}>
            <Box className="cart-items-wrapper">
              {cartItems?.map((cart, index) => (
                <CartItem key={index} cart={cart} />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              className="center"
              flexDirection="column"
              sx={{ m: { sm: "0px", xs: "0px 20px" } }}
            >
              <TotalCart cartItems={cartItems} />
              <Box className="center" gap="16px" flexWrap="wrap" mt="16px">
                <Button
                  onClick={() => navigate("/")}
                  variant="outlined"
                  className="cart-button"
                >
                  CONTINUE SHOPPING
                </Button>
                <Button
                  className="cart-button"
                  onClick={() => navigate("/checkout")}
                >
                  CHECKOUT NOW
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <EmptyCart />
      )}
    </>
  );
};

export default Cart;
