import {
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import { updateDefaultAddressAction } from "../../Redux/Actions/AddressAction";
import AddressInfo from "./AddressInfo";

const SelectedAddress = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const [open, setOpen] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState();
  const [addAddress, setAddAddress] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setAddAddress(false);
    }, 100);
  };

  const handleOpen = () => {
    setDefaultAddress(selectedAddress._id);
    setOpen(true);
  };
  const selectedAddress = user?.address?.find((obj) => obj.isSelected);

  const handleChange = (e) => {
    setDefaultAddress(e.target.value);
  };

  const handleChangeDefaultAddress = () => {
    dispatch(updateDefaultAddressAction(defaultAddress));
    handleClose();
  };
  return (
    <>
      <Card className="delivery-info">
        <Typography variant="h2" color="primary">
          Delivery Address
        </Typography>
        <Typography variant="body1" fontWeight={600}>
          Your Order Will Be Delivered At
        </Typography>
        <Typography variant="body1">
          {selectedAddress?.address}, {selectedAddress?.sector},{" "}
          {selectedAddress?.city?.name}
        </Typography>
        <Button variant="outlined" sx={{ mt: "10px" }} onClick={handleOpen}>
          Change Address
        </Button>
      </Card>
      <Dialog onClose={handleClose} open={open}>
        <Box
          className="address-modal-wrapper"
          sx={{ width: { xs: "300px", sm: "400px", md: "500px" } }}
        >
          {addAddress ? (
            <AddressInfo isModal={true} handleClose={handleClose} />
          ) : (
            <Box sx={{ mt: "10px", p: "10px" }}>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  name="radioOptions"
                  value={defaultAddress}
                  onChange={handleChange}
                >
                  {user?.address?.map((obj) => (
                    <FormControlLabel
                      sx={{ mb: "10px" }}
                      key={obj._id}
                      value={obj._id}
                      control={<Radio />}
                      label={
                        <Box
                          className="modal-label-wrapper"
                          sx={{
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                        >
                          <Box
                            className="justify-between"
                            sx={{
                              width: { xs: "220px", sm: "320px", md: "420px" },
                            }}
                          >
                            <Typography variant="h4" color="primary">
                              {obj?.title}
                            </Typography>
                            {obj?.isSelected && (
                              <Box
                                className="default-tag "
                                sx={{
                                  background: theme.palette.primary.main,
                                }}
                              >
                                <Typography variant="body2">Default</Typography>
                              </Box>
                            )}
                          </Box>
                          <Typography>{obj?.address}</Typography>
                        </Box>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "16px",
                }}
              >
                <Button variant="outlined" onClick={() => setAddAddress(true)}>
                  Add New Address
                </Button>
                <Button onClick={handleChangeDefaultAddress}>
                  Save Changes
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default SelectedAddress;
