import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerOrdersAction } from "../../Redux/Actions/OrderAction";
import { Card, Chip, Stack } from "@mui/material";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import theme from "../../theme";
import { STATUS } from "../../utils/Enums";
import CustomePagination from "../../Components/SharedComponents/CustomPagination";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const { orders, totalCount, loading } = useSelector((state) => state.Order);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState("5");

  const handleNavigate = (id) => {
    navigate(`/order/${id}`);
  };
  useEffect(() => {
    dispatch(
      customerOrdersAction({
        id: user?._id,
        skip: currentPage * Number(pageSize),
        limit: pageSize,
      })
    );
  }, [user?._id, dispatch, pageSize, currentPage]);

  const columns = [
    {
      field: "id",
      headerName: "OrderId#",
      width: 90,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 120,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "returnAmount",
      headerName: "Returned Amount",
      width: 145,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "totalAmount",
      headerName: "Pending Amount",
      width: 140,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.row.status === STATUS.DELIVERED
              ? "0"
              : cellValues.value}
          </div>
        );
      },
    },
    {
      field: "returnType",
      headerName: "Return Status",
      width: 130,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Chip
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: 500,
            }}
            label={cellValues.value}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Chip
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: 500,
            }}
            label={cellValues.value}
          />
        );
      },
    },
    {
      field: "_id",
      headerName: "Details",
      width: 90,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Chip
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: 500,
              background: theme.palette.primary.main,
              cursor: "pointer",
            }}
            label="Details"
            onClick={() => {
              handleNavigate(cellValues.value);
            }}
          />
        );
      },
    },
  ];

  return !loading ? (
    <Card sx={{ height: 600, width: "100%", borderRadius: "12px" }}>
      <DataGrid
        disableSelectionOnClick
        pageSize={Number(pageSize)}
        columns={columns}
        rows={orders.map((order, index) => ({ id: index + 1, ...order }))}
        rowCount={totalCount || 0}
        page={currentPage}
        paginationMode="server"
        disableColumnMenu={true}
        sx={{
          borderRadius: "12px",
          "&.MuiDataGrid-root": {
            border: "none",
            color: theme?.palette.text.disabled,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F5F5F5",
            fontSize: 16,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            maxWidth: "3px",
            maxHeight: "3px",
          },
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No Result Found
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returns no result
            </Stack>
          ),
          Footer: () => (
            <GridFooterContainer
              sx={{
                backgroundColor: "#F5F5F5",
                position: "relative",
                height: "55px",
              }}
            >
              <CustomePagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalCount={totalCount || 0}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </GridFooterContainer>
          ),
        }}
      />
    </Card>
  ) : (
    <>Loading...</>
  );
};

export default Orders;
