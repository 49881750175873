import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./styles.css";
import banner1 from "../../assests/images/banner1.png";
import banner2 from "../../assests/images/banner2.png";
import { Slide } from "react-slideshow-image/dist";
const fadeImages = [banner1, banner2];

const ImageSlider = () => {
  return (
    <div className="slide-container" style={{ paddingTop: "50px" }}>
      <Slide duration={3000}>
        {fadeImages.map((image, index) => (
          <div className="each-fade" key={index} style={{padding:"0px 20px"}}>
            <img
              src={image}
              alt={`banner-${index}`}
              loading="lazy"
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default ImageSlider;
