export const FETCH_CITIES_REQUEST = "FETCH_CITIES_REQUEST";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_CITIES_FAIL = "FETCH_CITIES_FAIL";

export const UPDATE_DEFAULT_ADDRESS = "UPDATE_DEFAULT_ADDRESS";
export const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS";

export const fetchCitiesAction = () => {
  return { type: FETCH_CITIES_REQUEST };
};

export const updateDefaultAddressAction = (id) => {
  return { type: UPDATE_DEFAULT_ADDRESS, payload: id };
};

export const addNewAddressAction = (data) => {
  return { type: ADD_NEW_ADDRESS, payload: data };
};
