import {
  ADD_TO_CART,
  CLEAR_CART,
  REMOVE_FROM_CART,
} from "../Actions/CartAction";

const initialState = {
  cart: [],
};

export const CartReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_CART: {
      const itemCodeToFind = payload.item.itemCode;
      const existingIndex = state.cart.findIndex(
        (obj) => obj.item.itemCode === itemCodeToFind
      );
      if (existingIndex !== -1) {
        state.cart[existingIndex] = payload;
      } else {
        state.cart.push(payload);
      }
      return { cart: [...state.cart] };
    }
    case REMOVE_FROM_CART: {
      const itemCodeToRemove = payload.itemCode;
      const existingIndex = state.cart.findIndex(
        (obj) => obj.item.itemCode === itemCodeToRemove
      );
      if (existingIndex >= 0) {
        state.cart.splice(existingIndex, 1);
      }
      return { cart: [...state.cart] };
    }
    case CLEAR_CART: {
      return { cart: [] };
    }
    default:
      return state;
  }
};
