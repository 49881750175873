import React from "react";
import Home from "../Views/Home/Home";
import Login from "../Views/Login/Login";
import Cart from "../Views/Cart/Cart";
import PrivateRoutes from "./PrivateRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../Components/Header/Header";
import SideBar from "../Components/SideBar/SideBar";
import { Box } from "@mui/material";
import "./routes.css";
import Item from "../Views/Item/Item";
import CheckOut from "../Views/Checkout/CheckOut";
import SignUp from "../Views/Login/SignUp";
import VerfiyCode from "../Views/Login/Verfiy";
import ForgotPassword from "../Views/Login/ForgotPassword";
import ResetPassword from "../Views/Login/ResetPassword";
import Profile from "../Views/Profile/Profile";
import AddItems from "../Views/AddItems/AddItems";
import Order from "../Views/Orders/Order";
import AddCategories from "../Views/AddCategorys/AddCategories";
import UserRoutes from "./UserRoutes";
import ProcessOrders from "../Views/Orders/ProcessOrders";
import Category from "../Views/Category/Category";
import About from "../Views/About/About";
import UpdateRates from "../Views/UpdateRates/UpdateRates";
import ContactUs from "../Views/ContactUs/ContactUs";

const index = () => {
  return (
    <Box sx={{ backgroundColor: "#f8f9fa" }}>
      <BrowserRouter>
        <Header />
        <SideBar />
        <Box
          className="route-wrapper"
          sx={{
            height: { xs: "calc(100vh - 60px)", sm: "calc(100vh - 65px)" },
          }}
        >
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/orders" element={<ProcessOrders />} />
              <Route path="/add-item" element={<AddItems />} />
              <Route path="/add-category" element={<AddCategories />} />
              <Route path="/update-rates" element={<UpdateRates />} />
              <Route path="/edit-item/:itemId" element={<AddItems />} />
            </Route>
            <Route element={<UserRoutes />}>
              <Route path="/user/:action" element={<Profile />} />
              <Route path="/order/:orderId" element={<Order />} />
            </Route>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/:category/:categoryId" element={<Category />} />
            <Route path="/:category/:item/:itemCode" element={<Item />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password/verify" element={<VerfiyCode />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </Box>
  );
};

export default index;
