import { Box, Card, Divider, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { calculateDiscount, calculateTotal } from "../../utils/Calculations";
import "./checkout.css";

const OrderSummary = ({ selectedItems, city }) => {
  const { subTotal, deliveryCharges, packingTotal, grandTotal } = useMemo(
    () => calculateTotal(selectedItems, city),
    [selectedItems, city]
  );
  return (
    <Card className="summary-card">
      <Typography variant="h2" color="primary">
        Order Summary
      </Typography>
      <Box className="summary-item-details summary-header">
        <Typography
          className="summary-item-product"
          variant="h4"
          color="textPrimary"
        >
          Products
        </Typography>
        <Typography
          className="summary-item-qty"
          variant="h4"
          color="textPrimary"
        >
          Qty
        </Typography>
        <Typography
          className="summary-item-price"
          variant="h4"
          color="textPrimary"
        >
          Price
        </Typography>
      </Box>
      {selectedItems.map((items, index) => (
        <Box key={index}>
          <Box className="summary-item-details">
            <Typography className="summary-item-product" variant="h4">
              {items.item.name}
            </Typography>
            <Typography
              className="summary-item-qty"
              variant="h4"
              fontWeight={500}
            >
              {items.quantity}
            </Typography>
            <Typography
              className="summary-item-price"
              variant="h4"
              fontWeight={500}
            >
              RS &nbsp;
              {items.quantity *
                calculateDiscount(items.item.price, items.item.discount)}
            </Typography>
          </Box>
          <Divider className="summary-divider-line" />
        </Box>
      ))}
      <Box className="summary-title-box">
        <Typography className="summary-total-title" variant="h4">
          Subtotal:
        </Typography>
        <Typography className="summary-total-price" variant="h4">
          Rs {subTotal}
        </Typography>
      </Box>
      <Divider className="summary-divider-line" />
      <Box className="summary-title-box">
        <Typography className="summary-total-title" variant="h4">
          Delivery Charges:
        </Typography>
        <Typography className="summary-total-price" variant="h4">
          Rs {deliveryCharges}
        </Typography>
      </Box>
      <Divider className="summary-divider-line" />
      <Box className="summary-title-box">
        <Typography className="summary-total-title" variant="h4">
          Packing Charges:
        </Typography>
        <Typography className="summary-total-price" variant="h4">
          Rs {packingTotal}
        </Typography>
      </Box>
      <Divider className="summary-divider-line" />
      <Box className="summary-title-box">
        <Typography className="summary-total-title" variant="h4">
          Total:
        </Typography>
        <Typography className="summary-total-price" variant="h4">
          Rs {grandTotal}
        </Typography>
      </Box>
      <Divider className="summary-divider-line" />
    </Card>
  );
};

export default OrderSummary;
