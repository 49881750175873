import React, { useMemo } from "react";
import { Box, Card, Divider, Typography } from "@mui/material";
import { calculateTotal } from "../../utils/Calculations";
import { useSelector } from "react-redux";

const TotalCart = ({ cartItems }) => {
  const { user } = useSelector((state) => state.Auth);
  const city = useMemo(
    () => user?.address?.find((obj) => obj.isSelected)?.city?.name,
    [user?.address]
  );
  const { subTotal, deliveryCharges, packingTotal, grandTotal } = useMemo(
    () => calculateTotal(cartItems, city),
    [cartItems, city]
  );
  return (
    <Card className="total-card">
      <Box className="title-box">
        <Typography className="total-title" variant="h4">
          Subtotal:
        </Typography>
        <Typography className="total-price" variant="h4">
          Rs {subTotal}
        </Typography>
      </Box>
      <Divider className="divider-line" />
      <Box className="title-box">
        <Typography className="total-title" variant="h4">
          Delivery Charges:
        </Typography>
        <Typography className="total-price" variant="h4">
          Rs {deliveryCharges}
        </Typography>
      </Box>
      <Divider className="divider-line" />
      <Box className="title-box">
        <Typography className="total-title" variant="h4">
          Packing Charges:
        </Typography>
        <Typography className="total-price" variant="h4">
          Rs {packingTotal}
        </Typography>
      </Box>
      <Divider className="divider-line" />
      <Box className="title-box">
        <Typography className="total-title" variant="h4" color="primary">
          Total:
        </Typography>
        <Typography className="total-price" variant="h4" color="primary">
          Rs {grandTotal}
        </Typography>
      </Box>
      <Divider sx={{ margin: "10px 0px 0px", width: "100%" }} />
    </Card>
  );
};

export default TotalCart;
