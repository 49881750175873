import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { ToastAction } from "../../Redux/Actions/ToastAction";

// const MuiAlert = React.forwardRef(function Alert(props, ref) {
//   return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const Toast = (props) => {
  const dispatch = useDispatch();
  const { open, severity, message } = props;
  const handleClose = (event) => {
    dispatch(ToastAction({ hide: true }));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right"}}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%", marginTop:"60px", border:`1px solid red}`}}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
