import { Box, Button, CardMedia, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteItem,
  fetchCategotyItems,
  fetchSingleItemRequest,
} from "../../Redux/Actions/ItemActon";
import "./item.css";
import { calculateDiscount } from "../../utils/Calculations";
import AddToCartModal from "../../Components/SharedComponents/AddToCartModal";
import { DeleteForever, EditNote } from "@mui/icons-material";
import ItemCard from "../../Components/SharedComponents/ItemCard";

const Item = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { item, loading } = useSelector((state) => state.Items);
  const { category, loading: categoryLoading } = useSelector(
    (state) => state.Items
  );
  const { user } = useSelector((state) => state.Auth);
  const { _id, name, urduName, price, type, discount, imageUrl, description, inStock } =
    item;
  useEffect(() => {
    const itemCode = params?.itemCode;
    if (itemCode) {
      dispatch(
        fetchSingleItemRequest({
          categoryName: params.category,
          itemCode: itemCode,
        })
      );
    }
  }, [dispatch, params]);

  useEffect(() => {
    if (item?.category?._id) {
      dispatch(fetchCategotyItems(item.category._id));
    }
  }, [item]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeletItem = () => {
    dispatch(deleteItem(_id));
  };
  return (
    !loading && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AddToCartModal item={item} isOpen={isOpen} handleClose={handleClose} />
        <Box
          sx={{
            display: "flex",
            maxWidth: "1200px",
            flexWrap: "wrap",
            m: "auto",
            justifyContent: { xs: "center", sm: "space-evenly" },
            pt: { xs: "2rem", sm: "5rem" },
            gap: "20px",
          }}
        >
          <Box
            sx={{
              width: { xs: "70%", sm: "40%", md: "30%" },
              borderRadius: "8px",
            }}
          >
            <CardMedia
              sx={{
                width: "100%",
                aspectRatio: 1 / 1,
                borderRadius: "8px",
              }}
              image={imageUrl}
              title={name}
              src={imageUrl}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "70%", sm: "40%" },
              display: "flex ",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h2" sx={{ color: "#525252" }}>
                {name}
              </Typography>
              <Box
                sx={{
                  display:
                    user?.type === "seller" || user?.type === "admin"
                      ? "block"
                      : "none",
                }}
              >
                <IconButton onClick={() => navigate(`/edit-item/${_id}`)}>
                  <EditNote fontSize="large" />
                </IconButton>
                <IconButton onClick={handleDeletItem}>
                  <DeleteForever />
                </IconButton>
              </Box>
            </Box>
            <Typography variant="h2" sx={{ color: "#525252" }}>
              {urduName}
            </Typography>
            <Typography
              sx={{
                background: "#f44336",
                color: "#fff",
                p: "0 5px",
                borderRadius: "6px",
                width: "40px",
                fontSize: "12px !Important",
              }}
            >
              {type}
            </Typography>
            {discount ? (
              <Typography color="primary" variant="h3" fontWeight={500}>
                <del style={{ color: "#A1A1A2" }}>Rs{item.price}</del> &nbsp; Rs
                {calculateDiscount(price, discount)}
              </Typography>
            ) : (
              <Typography color="primary" variant="h3" fontWeight={500}>
                Rs{price}
              </Typography>
            )}
            <Typography variant="h6" sx={{ color: "#525252" }}>
              {description}
            </Typography>
            <br />
            <Button
              disabled={!inStock}
              onClick={(e) => {
                setIsOpen(true);
              }}
              sx={{ width: { xs: "100%", sm: "200px" }, borderRadius: "50px" }}
            >
              Add to Cart
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            px: "3rem",
          }}
        >
          <Typography variant="h2" sx={{ color: "#525252" }}>
            People also buy
          </Typography>
          <Box
            sx={{
              overflowX: "scroll",
              height: "200px",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              scrollbarWidth: "none",
              marginBottom: "20px",
            }}
          >
            {categoryLoading ? (
              <>loading...</>
            ) : (
              category?.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    minWidth: "160px",
                    maxWidth: "160px",
                  }}
                >
                  <ItemCard item={item} category={item.category._id} />
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Box>
    )
  );
};

export default Item;
