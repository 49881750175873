import { Avatar, Card, CardContent, Typography } from "@mui/material";
import React from "react";

const About = () => {
  return (
    <div className="center">
      <Card
        sx={{
          borderRadius: "8px",
          m: "20px",
          p: { sm: "20px", md: "40px" },
          maxWidth: "1000px",
        }}
      >
        <CardContent className="profile-content">
          <Avatar
            alt={"mandi mart"}
            src="https://i.ibb.co/Kr77P73/Mandi-Mart-1-1-01.jpg"
            className="item-image profile-avatar"
            sx={{ border: "1px solid #E3B625" }}
          />
          <Typography variant="h1" color="primary">
            {"Mandi Mart"}
          </Typography>
          <Typography
            sx={{
              fontSize: { sm: "16px !important", md: "20px !important" },
              textAlign: "justify",
              mt: "12px",
            }}
          >
            Mandi Mart began its journey in 2023 in Gujranwala with a mission to
            bridge the gap in the agricultural value chain. Our goal is to
            connect farmers directly with consumers, ensuring fair prices for
            farmers and top-quality produce for our customers. Recently, we have
            expanded our services online through a user-friendly website and a
            dedicated helpline for orders, with plans to launch Android and iOS
            apps soon. We take pride in delivering fresh fruits and vegetables
            straight from the fields to your doorstep. Our comprehensive
            approach involves sourcing, purchasing, packaging, and delivery, all
            under one roof. At Mandi Mart, we prioritize customer comfort and
            satisfaction, guaranteeing a delightful experience with every
            purchase. Visit our shop at Fruit and Vegetables Market, Kamoke,
            Gujranwala, to personally select the finest produce. Our passionate
            team is dedicated to supporting farmers while delivering farm-fresh
            goodness to your kitchen.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default About;
