import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchCategotyItems } from "../../Redux/Actions/ItemActon";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import ItemCard from "../../Components/SharedComponents/ItemCard";

const Category = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { category, loading } = useSelector((state) => state.Items);
  const categoryName = params.category;
  useEffect(() => {
    if (params.categoryId) {
      dispatch(fetchCategotyItems(params.categoryId));
    }
  }, [dispatch, params?.categoryId]);

  return (
    <Box className="main-wrapper">
      {loading ? (
        <Box className="main-wrapper">
          <Skeleton
            variant="rectangular"
            sx={{
              width: "40%",
              height: "30px",
              mb: "10px",
              borderRadius: "4px",
            }}
          />
          <Grid container spacing={2}>
            {[1, 2, 3, 4].map((_, index) => (
              <Grid
                key={index}
                item
                xs={12}
                m370={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                className="items-wrapper"
              >
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={230}
                  sx={{
                    width: "100%",
                    height: 230,
                    borderRadius: "4px",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <>
          <Typography variant="h2" color={"#B52B2B"}>
            {category?.[0]?.category?.name ?? categoryName}
          </Typography>
          <Grid container spacing={2}>
            {category?.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                m340={6}
                m370={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                className="items-wrapper"
              >
                <ItemCard
                  item={item}
                  category={category?.[0]?.category?.name ?? categoryName}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Category;
