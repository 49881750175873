import { all } from "redux-saga/effects";
import ToggleSaga from "./ToggleSaga";
import ItemSaga from "./ItemSaga";
import AuthSaga from "./authSaga";
import ToastSaga from "./ToastSaga";
import AddressSaga from "./AddressSaga";
import OrderSaga from "./OrderSaga";

function* rootSagas() {
  yield all([
    ToggleSaga(),
    ItemSaga(),
    AuthSaga(),
    ToastSaga(),
    AddressSaga(),
    OrderSaga(),
  ]);
}
export default rootSagas;
