import React, { useState, useEffect, useMemo } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  MenuItem,
  Menu,
  Badge,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideBarAction } from "../../Redux/Actions/ToggleAction";
import { Link, useNavigate } from "react-router-dom";
import { logoutAction } from "../../Redux/Actions/AuthAction";
import { calculateTotal } from "../../utils/Calculations";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "300px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    },
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSideBar = useSelector((state) => state?.Toggle?.sideBar);
  const cartItems = useSelector((state) => state?.Cart.cart);
  const token = useSelector((state) => state?.Auth?.token);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { subTotal } = useMemo(() => calculateTotal(cartItems), [cartItems]);

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  const isMenuOpen = Boolean(anchorEl);

  const handleSibeBar = () => {
    dispatch(toggleSideBarAction(!isSideBar));
  };

  const handleProfileMenuOpen = (event) => {
    if (isAuthenticated) {
      setAnchorEl(event.currentTarget);
    } else {
      navigate("/login");
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    navigate("/");
    handleMenuClose();
  };

  const handleCart = () => {
    navigate("/cart");
  };

  const handleProfile = () => {
    navigate("/user/profile");
    handleMenuClose();
  };

  const handleOrders = () => {
    navigate("/user/orders");
    handleMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <MenuItem onClick={handleOrders}>Orders history</MenuItem>
      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleSibeBar}
          >
            <MenuIcon fontSize="large" />
          </IconButton>

          <Typography
            variant="h3"
            noWrap
            sx={{
              // display: { xs: "none", sm: "flex" },
              ml: { xs: -2, sm: 0 },
              display: "flex",
              textDecoration: "none",
              cursor: "pointer",
              color: "#fff",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
            }}
            component={Link}
            to={"/"}
          >
            <Avatar
              sx={{ width: 28, height: 28 }}
              alt="Mandi"
              src="https://i.ibb.co/Kr77P73/Mandi-Mart-1-1-01.jpg"
            />
            Mandi Mart
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon sx={{ fontSize: { xs: "18px", sm: "24px" } }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search..."
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex" }}>
            <IconButton size="large" color="inherit" onClick={handleCart}>
              <Badge
                badgeContent={cartItems?.length}
                color="secondary"
                sx={{
                  "& .MuiBadge-badge": {
                    color: "#fff",
                    fontWeight: 500,
                    border: `2px solid #fff`,
                    padding: "0 4px",
                  },
                }}
              >
                <ShoppingCartTwoToneIcon
                  sx={{ fontSize: { xs: "24px", sm: "30px" } }}
                />
              </Badge>
              <Typography variant="h6" sx={{ ml: "6px" }}>
                RS&nbsp;{subTotal}
              </Typography>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle sx={{ fontSize: { xs: "24px", sm: "30px" } }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
};

export default Header;
