import {
  PermIdentity,
  PhoneIphone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Box, Button, Card, FormControl, FormHelperText, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signupAction } from "../../Redux/Actions/AuthAction";
import { fetchCitiesAction } from "../../Redux/Actions/AddressAction";

const REQUIRED = "This field is required";
const NAME = "Name should be less than 50 characters long.";
const NAME_TYPE = "Name must contain alphabets only.";
const PHONE = "Enter a valid phone number.";
const PASSWORD = "Password must have at least 4 characters";
const NAME_REG = /^[A-Za-z\s]+$/;
const PHONE_REG = /^03\d{9}$/;

const ProfileInfo = ({ control, watch,address, setAddress }) => {
  const dispatch = useDispatch();
  const [isVisibility, setIsVisibility] = useState(false);
  // const { handleSubmit, control, watch } = useForm({ mode: "onSubmit" });
  const password = watch("password", "");

  // const handleRegister = (data) => {
  //   dispatch(signupAction({ ...data, isCheckout: true }));
  // };

  const { cities } = useSelector((state) => state.Address);

  const selectedCity = useMemo(
    () => cities.find((city) => city?._id === address?.cityId),
    [address.cityId, cities]
  );
  useEffect(() => {
    dispatch(fetchCitiesAction());
  }, [dispatch]);
  return (
    <>
      <Card className="profile-info">
        <Typography variant="h2" color="primary">
          Profile Information
        </Typography>
        <Box className="info-box">
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <PhoneIphone sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <Controller
              name="cellNumber"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="03xx xxxxxxx"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                type: "number",
                pattern: {
                  value: PHONE_REG,
                  message: PHONE,
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <PermIdentity sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <Controller
              name="fullName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Your Full Name"
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                maxLength: {
                  value: 50,
                  message: NAME,
                },
                pattern: {
                  value: NAME_REG,
                  message: NAME_TYPE,
                },
              }}
            />
          </Box>
        </Box>
        <Box className="info-box">
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            {isVisibility ? (
              <Visibility
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(false)}
              />
            ) : (
              <VisibilityOff
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(true)}
              />
            )}
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Enter Your Password"
                  type={isVisibility ? "text" : "password"}
                  sx={{ width: "100%" }}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                minLength: {
                  value: 4,
                  message: PASSWORD,
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            {isVisibility ? (
              <Visibility
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(false)}
              />
            ) : (
              <VisibilityOff
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => setIsVisibility(true)}
              />
            )}
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  variant="standard"
                  placeholder="Confirm Password"
                  type={isVisibility ? "text" : "password"}
                  sx={{ width: "100%" }}
                  name="confirmPassword"
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: REQUIRED,
                validate: (value) =>
                  value === password || "Passwords do not match",
              }}
            />
          </Box>
        </Box>
        {/* <Box display="flex" justifyContent="flex-end">
        <Button
          variant="outlined"
          sx={{ borderRadius: "50px", mt: "10px" }}
          onClick={handleSubmit(handleRegister)}
        >
          Add Details
        </Button>
      </Box> */}
      </Card>
      <Card className="address-info">
        <Typography variant="h2" color="primary">
          Delivery Address
        </Typography>
        <Box
          sx={{
            minWidth: "200px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            mt: "20px",
          }}
        >
          <FormControl fullWidth error={address.cityError}>
            <Select
              fullWidth
              variant="standard"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={address.cityId}
              sx={{ color: address.cityId === " " && "#999999" }}
              onChange={(e) =>
                setAddress((address) => ({
                  ...address,
                  cityId: e.target.value,
                  cityError: false,
                }))
              }
            >
              <MenuItem value=" ">Select City</MenuItem>
              {cities?.map((city, index) => (
                <MenuItem key={index} value={city?._id}>
                  {city?.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {address.cityError && "Please select a City"}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth error={address.sectorError}>
            <Select
              fullWidth
              variant="standard"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={address.sector}
              sx={{ color: address.sector === " " && "#999999" }}
              onChange={(e) =>
                setAddress((address) => ({
                  ...address,
                  sector: e.target.value,
                  sectorError: false,
                }))
              }
            >
              <MenuItem value=" ">Select Area</MenuItem>
              {selectedCity?.sector?.sort()?.map((area, index) => (
                <MenuItem key={index} value={area}>
                  {area}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {address.sectorError && "Please select your Area"}
            </FormHelperText>
          </FormControl>
        </Box>
        <TextField
          helperText={address.streetError && "Please enter your address"}
          error={address.streetError}
          sx={{ mt: "20px" }}
          fullWidth
          variant="standard"
          placeholder="Delivery Address"
          value={address.street}
          onChange={(e) =>
            setAddress((address) => ({
              ...address,
              street: e.target.value,
              streetError: false,
            }))
          }
        />
      </Card>
    </>
  );
};

export default ProfileInfo;
