import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CartIcon from "../../assests/svgs/CartIcon";
import { useNavigate } from "react-router-dom";

const EmptyCart = () => {
  const navigate = useNavigate();
  return (
    <Box
      className="center"
      flexDirection={"column"}
      sx={{
        height: "inherit",
        gap: "10px",
      }}
    >
      <CartIcon />
      <Typography variant="h2" color={"primary"}>
        Your Cart is Empty.
      </Typography>
      <Typography>
        Looks like you havenit added anything to the cart yet.
      </Typography>
      <Button
        onClick={() => navigate("/")}
        variant="outlined"
        className="cart-button"
      >
        CONTINUE SHOPPING
      </Button>
    </Box>
  );
};

export default EmptyCart;
