import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Stack } from "@mui/material";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import theme from "../../theme";
import CustomePagination from "../../Components/SharedComponents/CustomPagination";
import { editItem, fetchPaginatedItems } from "../../Redux/Actions/ItemActon";

const UpdateRates = () => {
  const dispatch = useDispatch();
  const { paginatedItems, totalCount, paginatedLoading: loading } = useSelector(
    (state) => state.Items
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState("10");

  useEffect(() => {
    dispatch(
      fetchPaginatedItems({
        skip: currentPage * Number(pageSize),
        limit: pageSize,
      })
    );
  }, [dispatch, pageSize, currentPage]);

  const handleUpdateItem = (data) => {
    dispatch(editItem(data));
  };

  const processRowUpdate = (newRow, oldRow) => {
    if (newRow.price !== oldRow.price || newRow.discount !== oldRow.discount || newRow.itemCode !== oldRow.itemCode) {
      handleUpdateItem({
        _id: newRow._id,
        price: newRow.price,
        discount: newRow.discount,
        itemCode: newRow.itemCode,
      });
    }
    return newRow;
  };

  const columns = [
    {
      field: "itemCode",
      headerName: "Code",
      width: 80,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      type: "number",
      editable: true,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Item Name",
      width: 200,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 80,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      type: "number",
      editable: true,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 90,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      type: "number",
      editable: true,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: "Disc Price",
      sortable: false,
      width: 90,
      valueGetter: ({ row }) => {
        return row.price - (row.price * row.discount) / 100;
      },
    },
  ];

  return !loading ? (
    <Box sx={{ p: "16px" }}>
      <Card
        sx={{
          height: "auto",
          width: "100%",
          borderRadius: "12px",
          maxWidth: "550px",
          m: "auto",
        }}
      >
        <DataGrid
          disableSelectionOnClick
          pageSize={Number(pageSize)}
          columns={columns}
          rows={paginatedItems.map((item) => ({
            id: item._id,
            ...item,
          }))}
          rowCount={totalCount || 0}
          page={currentPage}
          paginationMode="server"
          disableColumnMenu={true}
          processRowUpdate={processRowUpdate}
          sx={{
            borderRadius: "12px",
            "&.MuiDataGrid-root": {
              border: "none",
              color: theme?.palette.text.disabled,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F5F5F5",
              fontSize: 16,
              color: theme.palette.text.primary,
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              maxWidth: "3px",
              maxHeight: "3px",
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Result Found
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Local filter returns no result
              </Stack>
            ),
            Footer: () => (
              <GridFooterContainer
                sx={{
                  backgroundColor: "#F5F5F5",
                  position: "relative",
                  height: "55px",
                }}
              >
                <CustomePagination
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalCount={totalCount || 0}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </GridFooterContainer>
            ),
          }}
        />
      </Card>
    </Box>
  ) : (
    <>Loading...</>
  );
};

export default UpdateRates;
