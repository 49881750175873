import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { TOAST_SHOW } from "../Actions/ToastAction";
import {
  CREATE_ORDER_FAIL,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CUSTOMER_ORDERS_FAIL,
  CUSTOMER_ORDERS_REQUEST,
  CUSTOMER_ORDERS_SUCCESS,
  FETCH_ALL_ORDERS_FAIL,
  FETCH_ALL_ORDERS_REQUEST,
  FETCH_ALL_ORDERS_SUCCESS,
  FETCH_ORDER_FAIL,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
} from "../Actions/OrderAction";
import { CLEAR_CART } from "../Actions/CartAction";
import api from "../../utils/Axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function* createOrderSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/orders`,
      action.payload
    );
    const { data } = response;
    if (data) {
      yield put({
        type: CREATE_ORDER_SUCCESS,
        payload: data,
      });
      yield put({
        type: CLEAR_CART,
      });
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: "Your order has been placed",
          severity: "success",
        },
      });
      window.location.replace(`/order/${data._id}`);
    }
  } catch (error) {
    console.log(error);
    yield put({ type: CREATE_ORDER_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* customerOrdersSaga(action) {
  const { id, skip, limit } = action.payload;
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/orders/user/${id}?skip=${skip}&limit=${limit}`
    );
    const { data } = response;
    if (data) {
      yield put({
        type: CUSTOMER_ORDERS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: CUSTOMER_ORDERS_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* fetchOrderDetailsSaga(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/orders/${action.payload}`
    );
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_ORDER_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: FETCH_ORDER_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* fetchAllOrdersSaga(action) {
  const { skip, limit, status } = action.payload;
  try {
    const response = yield call(
      api.get,
      `${BASE_URL}/orders?skip=${skip}&limit=${limit}&status=${status}`
    );
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_ALL_ORDERS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: FETCH_ALL_ORDERS_FAIL });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* updateOrderSaga(action) {
  try {
    const response = yield call(
      api.put,
      `/orders/${action.payload._id}`,
      action.payload
    );

    const { data } = response;
    if (data) {
      yield put({
        type: UPDATE_ORDER_SUCCESS,
        payload: data,
      });
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: "Item successfully edited",
          severity: "success",
        },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: UPDATE_ORDER_FAIL, payload: error });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response?.data?.message,
        severity: "error",
      },
    });
  }
}

function* watchFetchOrder() {
  yield takeEvery(CREATE_ORDER_REQUEST, createOrderSaga);
  yield takeEvery(CUSTOMER_ORDERS_REQUEST, customerOrdersSaga);
  yield takeEvery(FETCH_ORDER_REQUEST, fetchOrderDetailsSaga);
  yield takeEvery(FETCH_ALL_ORDERS_REQUEST, fetchAllOrdersSaga);
  yield takeEvery(UPDATE_ORDER_REQUEST, updateOrderSaga);
}

export default function* OrderSaga() {
  yield all([watchFetchOrder()]);
}
