import { useDispatch, useSelector } from "react-redux";
import Toast from "./Components/Toast/Toast";
import AppRoutes from "./Routes/index";
import { useEffect } from "react";
import { getAllCategories } from "./Redux/Actions/ItemActon";

function App() {
  const ToastState = useSelector((state) => state.Toast);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  return (
    <>
      <Toast
        open={ToastState.open}
        message={ToastState.message}
        severity={ToastState.severity}
      />
      <AppRoutes />
    </>
  );
}

export default App;
