import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  CHANGE_PASSWORD,
  FETCH_USER_FAIL,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOG_OUT,
  SIGN_UP_FAIL,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
} from "../Actions/AuthAction";
import axios from "axios";
import { TOAST_SHOW } from "../Actions/ToastAction";
import { ADD_NEW_ADDRESS } from "../Actions/AddressAction";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function* signupSaga(action) {
  const { isCheckout, address, orderDetails, ...userData } = action.payload;
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/auth/signup`,
      userData
    );
    const { data } = response;
    if (data) {
      yield put({
        type: SIGN_UP_SUCCESS,
        payload: data,
      });
      if (action?.payload?.isCheckout) {
        yield put({
          type: ADD_NEW_ADDRESS,
          payload: {
            ...address,
            user: data.user._id,
            isCheckout,
            orderDetails: { ...orderDetails, user: data.user._id },
          },
        });
      } else {
        yield put({
          type: TOAST_SHOW,
          payload: {
            message: "Account created successfully",
            severity: "success",
          },
        });
        window.location.replace("/");
      }
    }
  } catch (error) {
    yield put({ type: SIGN_UP_FAIL, payload: error.response.data.message });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

export function* loginSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/auth/signin`,
      action.payload
    );
    const { data } = response;
    if (data) {
      yield put({
        type: LOGIN_SUCCESS,
        payload: data,
      });
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: "Logged In",
          severity: "success",
        },
      });
      window.location.replace("/");
    }
  } catch (error) {
    yield put({ type: LOGIN_FAIL, payload: error.response.data.message });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

export function* fetchUserSaga(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/users/${action.payload}`
    );
    const { data } = response;
    if (data) {
      yield put({
        type: FETCH_USER_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({ type: FETCH_USER_FAIL, payload: error.response.data.message });
  }
}

export function* logoutSaga() {
  return yield put({
    type: TOAST_SHOW,
    payload: {
      message: "Logged out",
      severity: "success",
    },
  });
}

export function* changePasswordSaga(action) {
  try {
    const response = yield call(
      axios.put,
      `${BASE_URL}/auth/change-password`,
      action.payload
    );
    const { data } = response;
    if (data) {
      yield put({
        type: TOAST_SHOW,
        payload: {
          message: data.message,
          severity: "success",
        },
      });
    }
  } catch (error) {
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: error.response.data.message,
        severity: "error",
      },
    });
  }
}

function* watchAuth() {
  yield takeEvery(SIGN_UP_REQUEST, signupSaga);
  yield takeEvery(LOGIN_REQUEST, loginSaga);
  yield takeEvery(LOG_OUT, logoutSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(FETCH_USER_REQUEST, fetchUserSaga);
}

export default function* AuthSaga() {
  yield all([watchAuth()]);
}
